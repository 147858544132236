@media (min-width: 1464px) {
  .match-container {
    // display: flex;
    // height: 80px;
    // align-items: center;
    // padding-left: 20px;
    // padding-right: 20px;
    // font-size: 14px;
    // color: #666666;
    display: flex;
    .match-costs-match-date {
      width: 30%;
    }

    .match-costs-match-info {
      align-items: center;
      width: 50%;
      justify-content: space-between;
      display: flex;

      .match-team {
        font-weight: 700;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
      }

      .match-score {
        font-weight: 700;
        font-size: 20px;
        /* width: 10%; */
        text-align: center;
      }
    }

    .match-costs-currency {
      width: 20%;
    }
  }

  .match-costs-container {
    // padding: 50px;
    .match-costs-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .matches-container {
      margin-bottom: 50px;
      .matches-container-title {
        font-size: 18px;
        font-weight: 700;
        padding-left: 20px;
        margin-bottom: 20px;
      }
      .match-see-more {
        .data-amount {
          font-size: 14px;
          color: var(--primaryColor);
          font-weight: 700;
          display: flex;
          align-items: center;
        }

        .eye-icon-container {
          background-color: #666666;
          width: 35px;
          height: 35px;
          padding: 7px;
          border-radius: 5px;
          cursor: pointer;
          margin-left: 30px;
        }
      }
    }

    .match-costs-arrow-icon {
      height: 15px;
      margin-right: 20px;
    }
  }
}

@media (max-width: 1464px) {
  .match-costs-match-info .match-team {
    .team-name {
      word-break: break-word;
    }
  }

  .match-container {
    display: flex;
    flex-direction: column;
  }

  .match-costs-container {
    // padding: 10px;
    .match-costs-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .matches-container {
      margin-bottom: 50px;

      .match-costs-match-info {
        display: flex;
      }

      .matches-container-title {
        font-size: 18px;
        font-weight: 700;
        padding-left: 20px;
        margin-bottom: 20px;
      }
      .match-see-more {
        .data-amount {
          font-size: 14px;
          color: var(--primaryColor);
          font-weight: 700;
          display: flex;
          align-items: center;
        }

        .eye-icon-container {
          background-color: #666666;
          width: 35px;
          height: 35px;
          padding: 7px;
          border-radius: 5px;
          cursor: pointer;
          margin-left: 30px;
        }
      }
    }

    .match-costs-arrow-icon {
      height: 15px;
      margin-right: 20px;
    }
  }
}
