@media (min-width: 991px) {
  .input-evaluation-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px 10px 0px;
    .input-evaluation-marks {
      width: 50%;
    }

    .input-evaluation-description {
      width: 95%;
    }
  }
  // .evaluation-add-player-header {
  //   margin-top: 12px;
  // }

  .rdt_TableBody {
    overflow-y: inherit !important;
  }

  .rdt_TableRow {
    .rdt_TableCell:not(:first-child) {
      justify-content: center;
    }
  }

  .rdt_TableCell:first-child {
    background-color: #fafafa;
    position: sticky !important;
    left: 0 !important;
    z-index: 1 !important;
  }

  [data-tag="allowRowEvents"] {
    // white-space: normal !important;
  }

  .rdt_TableCol:first-child,
  .rdt_TableRow:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  // .rdt_TableCell:nth-child(2) {
  //   background-color: #fafafa;
  //   position: sticky !important;
  //   left: 0 !important;
  //   z-index: 1 !important;
  // }

  // .rdt_TableCol:nth-child(2),
  // .rdt_TableRow:nth-child(2) {
  //   position: sticky;
  //   left: 0;
  //   z-index: 1;
  // }

  .evaluation-team-name {
    display: flex;
    // align-items: baseline;
    // grid-gap: 12px;
    gap: 12px;
    margin-top: 33px;

    .team-name {
      font-size: 20px;
      font-weight: 700;
    }

    .evaluation-team-checkbox {
      align-self: end;
    }
  }

  .evaluation-player-checkbox-wrapper {
    align-self: center;
  }

  .evaluation-row-player {
    // margin-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #666666;
    font-weight: 700;
  }

  .sub-categories-row {
    display: flex;
    flex-wrap: wrap;

    .sub-category {
      display: flex;
      margin-top: 20px;
      // margin-bottom: 40px;
      margin-right: 15px;
      gap: 5px;
      // align-items: center;
    }
  }

  .add-report-container {
    padding: 50px;

    .ui.button:hover .ui.button:active .ui.button:focus {
      background-color: #00d948 !important;
      background-image: none !important;
    }

    .players-list {
      margin-top: 50px;
    }

    .table-row {
      margin-top: 33px;

      .evaluation-category-name {
        display: flex;
        align-items: baseline;
        gap: 12px;
      }
    }

    .add-report-header {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .add-report-title {
        font-size: 20px;
        font-weight: 700;
      }

      .add-player-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 185px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-player-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }

    .add-report-form {
      margin-bottom: 50px;
      .add-report-row {
        height: 50px;
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        .add-report-input {
          width: 100%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;
        }

        select {
          width: 47%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;

          option {
            padding-left: 25px;
            background-color: #fafafa;
            color: #b2b2b2;
            font-size: 13px;
          }
        }
      }

      .add-report-text-area {
        width: 100%;
        height: 75px;
        font-size: 14px;
        font-weight: 700;
        color: #666666 !important;
        padding: 10px;
        padding-left: 20px;
        border: 0;
        background-color: #fafafa;
        margin-bottom: 15px;
      }

      .add-report-date-group {
        width: 47%;
        position: relative;
        height: 50px;

        .report-date-input {
          width: 100%;
          height: 50px;
          background-color: #fafafa;

          div {
            color: #b2b2b2;
            font-size: 14px;
            font-weight: 700;
            padding-left: 5px;
          }

          fieldset {
            border-color: #fafafa;
          }
        }

        .calendar-icon {
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .parameters-subtitle {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 25px;

      display: flex;
      justify-content: space-between;
    }

    .added-parameters-list {
      margin-bottom: 30px;
      .added-parameter-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 20px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
        }
      }
    }

    .add-player-container {
      margin-bottom: 65px;
      select {
        width: 100%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        margin-bottom: 15px;

        option {
          padding-left: 25px;
          background-color: #fafafa;
          color: #b2b2b2;
          font-size: 13px;
        }
      }

      .add-player-param-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 10px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
        }

        .add-player-value-input {
          font-size: 13px;
          font-weight: 700;
          color: #b2b2b2;
          width: 130px;
          text-align: center;
          background-color: #f0f0f0;
          border-radius: 3px;
          border: none;
          height: 35px;
        }
      }
    }

    .add-player-buttons-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 50px;
      margin-top: 25px;

      .add-report-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 245px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin-left: 15px;
      }

      .cancel-button {
        background-color: #666666 !important;
      }

      .add-report-button-icon {
        color: white;
        opacity: 1 !important;
      }
    }

    .players-list {
      .players-list-table-header {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #b2b2b2;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 13px;
      }

      .evaluation-row {
        // margin-bottom: 50px;

        padding: 25px 10px 25px 10px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #666666;
        font-weight: 700;

        .player-name {
          width: 50%;
          display: flex;
          align-items: center;

          .player-avatar {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-right: 15px;
            object-fit: cover;
          }
        }

        img {
          width: 10px;
        }
      }
    }

    .add-param-dropdown {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      color: #b2b2b2;

      option {
        padding-left: 25px;
        background-color: #fafafa;
        color: #b2b2b2;
        font-size: 13px;
      }
    }

    .add-new-param-row {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      .add-new-param-input {
        width: 65%;
        height: 100%;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
      }

      .add-new-param-input-um {
        width: 30%;
        min-width: 190px;
        height: 100%;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        margin-left: 15px;
      }

      .light-grey-button {
        background-color: #d4d4d4;
      }

      .add-param-buttons {
        display: flex;
        .add-param-button {
          margin-left: 10px;
          width: 50px;
          height: 50px;
          background-color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;

          .add-param-white-icon {
            color: white;
            opacity: 1 !important;
            margin: 0 !important;
          }
          .add-param-grey-icon {
            color: #666666;
            opacity: 1 !important;
            margin: 0 !important;
          }
        }
      }
    }

    .add-param-button-row {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      .add-param-final-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 245px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-param-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }

    .player-expanded-row {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 25px;
      padding-left: 20px;
      padding-right: 20px;

      .param-container {
        width: 33%;
        height: 50px;
        margin-bottom: 25px;
        .param-name {
          font-size: 13px;
          color: #b2b2b2;
          font-weight: 700;
        }
        .param-value {
          font-size: 20px;
          color: var(--primaryColor);
          font-weight: 700;
        }
      }
    }

    .final-report-submit-button-row {
      .ui.button:hover {
        background-color: #00d948 !important;
        background-image: none;
      }

      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      height: 50px;

      .final-report-submit-button {
        background-color: #00d948;
        height: 50px;
        width: 185px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .final-report-submit-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .add-report-container {
    padding: 15px;

    .add-report-header {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;

      .add-report-title {
        font-size: 20px;
        font-weight: 700;
      }

      .add-player-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 100%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-player-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }

    .add-report-form {
      margin-bottom: 50px;
      .add-report-row {
        height: 50px;
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        .add-report-input {
          width: 100%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;
        }

        select {
          width: 47%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;

          option {
            padding-left: 25px;
            background-color: #fafafa;
            color: #b2b2b2;
            font-size: 13px;
          }
        }
      }

      .add-report-text-area {
        width: 100%;
        height: 75px;
        font-size: 14px;
        font-weight: 700;
        color: #666666 !important;
        padding: 10px;
        padding-left: 20px;
        border: 0;
        background-color: #fafafa;
        margin-bottom: 15px;
      }

      .add-report-date-group {
        width: 47%;
        position: relative;
        height: 50px;

        .MuiInputBase-input {
          font-size: 12px !important;
        }

        .report-date-input {
          width: 100%;
          height: 50px;
          background-color: #fafafa;

          div {
            color: #b2b2b2;
            font-size: 14px;
            font-weight: 700;
            padding-left: 5px;
          }

          fieldset {
            border-color: #fafafa;
          }
        }

        .calendar-icon {
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .parameters-subtitle {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    .added-parameters-list {
      margin-bottom: 30px;
      .added-parameter-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 20px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
        }
      }
    }

    .add-player-container {
      margin-bottom: 65px;
      select {
        width: 100%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        margin-bottom: 15px;

        option {
          padding-left: 25px;
          background-color: #fafafa;
          color: #b2b2b2;
          font-size: 13px;
        }
      }

      .add-player-param-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 10px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
        }

        .add-player-value-input {
          font-size: 13px;
          font-weight: 700;
          color: #b2b2b2;
          width: 130px;
          text-align: center;
          background-color: #f0f0f0;
          border-radius: 3px;
          border: none;
          height: 35px;
        }
      }
    }

    .add-player-buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 50px;
      margin-top: 25px;
      gap: 15px;
      margin-top: 70px;

      .add-report-button {
        background-color: var(--primaryColor);
        min-height: 50px;
        width: 100%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin-left: 15px;
      }

      .cancel-button {
        background-color: #666666 !important;
      }

      .add-report-button-icon {
        color: white;
        opacity: 1 !important;
      }
    }

    .players-list {
      .players-list-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #b2b2b2;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 13px;
      }

      .evaluation-row {
        // height: 80px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #666666;
        font-weight: 700;

        .player-name {
          display: flex;
          align-items: center;

          .player-avatar {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-right: 15px;
            object-fit: cover;
          }
        }

        img {
          width: 10px;
        }
      }
    }

    .add-param-dropdown {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      color: #b2b2b2;

      option {
        padding-left: 25px;
        background-color: #fafafa;
        color: #b2b2b2;
        font-size: 13px;
      }
    }

    .add-new-param-row {
      // height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;

      .param-measurement-container {
        display: flex;
        flex-direction: column;
      }

      .add-new-param-input {
        // width: 65%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        margin-bottom: 10px;
        background-color: #fafafa;
        color: #b2b2b2;
      }

      .add-new-param-input-um {
        // width: 30%;
        // min-width: 190px;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        // margin-left: 15px;
        margin-bottom: 10px;
      }

      .light-grey-button {
        background-color: #d4d4d4;
      }

      .add-param-buttons {
        display: flex;
        margin-top: 20px;

        .add-param-button {
          margin-left: 10px;
          width: 50px;
          height: 50px;
          background-color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;

          .add-param-white-icon {
            color: white;
            opacity: 1 !important;
            margin: 0 !important;
          }
          .add-param-grey-icon {
            color: #666666;
            opacity: 1 !important;
            margin: 0 !important;
          }
        }
      }
    }

    .add-param-button-row {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      .add-param-final-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 100%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-param-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }

    .player-expanded-row {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 25px;
      padding-left: 20px;
      padding-right: 20px;

      .param-container {
        width: 33%;
        height: 50px;
        margin-bottom: 25px;
        .param-name {
          font-size: 13px;
          color: #b2b2b2;
          font-weight: 700;
        }
        .param-value {
          font-size: 20px;
          color: var(--primaryColor);
          font-weight: 700;
        }
      }
    }

    .final-report-submit-button-row {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      height: 50px;

      .final-report-submit-button {
        background-color: #00d948;
        height: 50px;
        width: 185px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .final-report-submit-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }
  }
}
