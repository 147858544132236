.add-recurrent-form {
  // min-width: 400px;
  padding-left: 26px;
  padding-right: 26px;

  .add-recurrent-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 30px;
    .add-recurrent-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }
  }

  .add-recurrent-row2 {
    input {
      height: initial !important;
    }
  }

  .add-recurrent-row {
    justify-content: space-between;
    gap: 5px;
    align-items: center;

    margin-bottom: 15px;
    display: flex;
    position: relative;

    .add-recurrent-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;
      width: 100%;

      i {
        top: 16px !important;
      }
    }

    .ui.dropdown:not(.button) > .default.text {
      color: #b2b2b2 !important;
    }

    .ui.dropdown > .text {
      display: inline-block;
      transition: none;
      color: #b2b2b2 !important;
    }

    .add-icon {
      cursor: pointer;
    }

    .recurrent-select-teams {
      width: 100%;
    }
  }

  .add-recurrent-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 50px;

    .add-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      height: 100%;
    }
  }

  .start-hour-icon {
    position: relative !important;
    right: 29px !important;
  }

  .start-date-icon {
    position: relative !important;
    right: 29px !important;
  }

  .recurrent-training-hour-input {
    background-color: #fafafa !important;
    border: 0 !important;
    align-items: center;

    height: 50px;
    width: 100%;

    color: #b2b2b2;
    font-size: 14px;
    font-weight: 700;

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
      width: 100%;
      height: 50px;

      .MuiOutlinedInput-notchedOutline {
        border-style: none;
      }
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
      background-color: #fafafa;
      height: 0px !important;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding-left: 20px;
      width: 100%;
      border-radius: 3px;
    }
  }

  .more-gap-recurrent {
    display: flex !important;
    gap: 22px !important;
  }
}
