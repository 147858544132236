.partner-header {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  .partner-title {
    display: flex;
    font-weight: 700;
    font-size: 20px;

    .name {
      margin-left: 8px;
    }
  }

  .edit-partner-button {
    height: 50px;
    color: white !important;
    background-color: #666666 !important;
    white-space: nowrap;
    font-family: "Poppins", sans-serif !important;
  }
}

.end-month-sold-header {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-left: 20px;
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 17px;
}

.partner-profile-table-row {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  font-weight: 700;
  color: #666666;

  .partner-profile-table-cell {
    display: flex;
  }
}

.expanded-transaction-container {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  color: #666666;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-header-partner-profile {
  color: #b3b3b3;
}

.last-trans-profile {
  width: 28%;
}

.column-left-align {
}

.column-center-align {
  place-content: center;
}

.column-end-align {
  place-content: flex-end;
}

.partner-profile-table-container {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
}

.partner-no-transactions-placeholder {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
}

.edit-partner-popup-container {
  margin-bottom: 10px;

  .edit-partner-input {
    width: 100%;
    height: 40px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #666666;
    border: solid;
  }
}

.transaction-edit-icon {
  color: #666666;
  padding-right: 25px;
  cursor: pointer;
}

.expand-icon-transactions {
  font-size: 25px !important;
  cursor: pointer;
}

.edit-transaction-container {
  .edit-transaction-row {
    width: 100%;
    height: 50px;

    .edit-partner-input {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
      border: 1px solid;
    }

    .transaction-date-input {
      width: 100%;
      height: 40px;
      background-color: #fafafa;
      border: solid 1px;

      div {
        color: #666666;
        font-weight: 700;
        padding-left: 5px;
        height: 100%;
      }

      fieldset {
        border-color: #fafafa;
      }
    }
  }
}
.eur-icon-transaction {
  color: #666666;
}
