.add-player-weight-popup {
  width: 100% !important;
}

.weight-control-container {
  padding: 50px;

  .player-name div.MuiAvatar-root.MuiAvatar-circle.MuiAvatar-colorDefault {
    align-items: center;
    justify-content: center;
  }

  .weight-control-header {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .weight-control-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }

    .weight-control-team-dropdown {
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      min-width: 200px !important;

      .divider.default.text {
        color: white;
      }

      i {
        top: 36% !important;
        color: white;
      }

      .divider.text {
        color: white;
      }
    }
  }

  .period-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;

    .date-button-icon {
      color: white !important;
      opacity: 1 !important;
      margin: 0 !important;
    }

    img {
      height: 15px;
      cursor: pointer;
    }

    .left-arrow {
      transform: rotate(90deg);
    }

    .right-arrow {
      transform: rotate(270deg);
    }

    .period {
      font-weight: 700;
      font-size: 25px;
      color: black;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .weight-control-table-header {
    display: flex;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
  }

  .weight-control-list {
    .player-row {
      height: 80px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      padding-left: 20px;
      padding-right: 20px;

      .player-options {
        width: 7%;
        display: flex;
        justify-content: center;

        .add-weight-button-container {
          width: 50px;
          height: 50px;
          cursor: pointer;
          border-radius: 3px;
          background-color: var(--primaryColor);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .weight-control-add-icon {
          color: white !important;

          margin: 0 !important;
        }
      }
    }
  }
  .player-name {
    width: 21%;
    display: flex;
    align-items: center;
  }
  .player-day {
    width: 9%;
    text-align: center;
  }

  .optimal-weight {
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #00d948;
    cursor: pointer;
  }
}

.add-player-weight-popup {
  width: 20vw;
  margin-bottom: 25px;
  .add-player-weight-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    .add-weight-title {
      font-weight: 700;
      font-size: 20px;
      color: black;
    }
  }

  .add-weight-input {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #666;
    margin-bottom: 15px;
  }

  .weight-date-input {
    width: 100%;
    height: 50px;
    background-color: #fafafa;

    div {
      color: #666;
      font-weight: 700;
      padding-left: 5px;
    }

    fieldset {
      border-color: #fafafa;
    }
  }

  .weight-calendar-icon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .add-weight-date-row {
    position: relative;
    margin-bottom: 15px;
  }

  .add-player-weight-button {
    background-color: var(--primaryColor) !important;
    font-family: Poppins, sans-serif !important;
    font-size: 14px !important;
    color: #fff !important;
    height: 50px;
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    .player-weight-button-icon {
      color: white;
      opacity: 1 !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  .desktop-header {
    display: flex !important;
  }

  .header-row {
    display: none !important;
  }

  .content-row {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {
  .content-row {
    .player-options {
      width: 100% !important;
      display: flex;
      justify-content: center;

      .add-weight-button-container {
        width: 100% !important;
        height: 30px !important;
        cursor: pointer;
        border-radius: 3px;
        background-color: var(--primaryColor);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .weight-control-add-icon {
        color: white !important;

        margin: 0 !important;
      }
    }
  }

  .wrapper-weight-control-team-dropdown {
    width: 100%;
    .weight-control-team-dropdown {
      width: 100%;
    }
  }

  .weight-control-container {
    .fullname {
      width: 100% !important;
    }

    .player-name {
      height: 60px !important;
      align-items: center !important;
    }

    .desktop-header {
      display: none !important;
    }

    .player-row {
      width: 100%;
      height: auto !important;
      padding: 20px !important;
      align-items: flex-start !important;
      flex-direction: row !important;
    }

    .training-row-table-header.header-row {
      padding: 0 !important;
    }

    .header-row {
      display: block;
      width: 43% !important;
      height: 100%;
    }

    .mobile-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 !important;

      .player-name {
        align-items: flex-start;
        padding: 0 0 15px 0 !important;

        div {
          width: 50px !important;
          height: 60px !important;
          padding: 0 !important;
        }
      }

      div {
        display: flex;
        justify-content: flex-start;
      }

      .optimal-weight {
        width: 100% !important;
        justify-content: flex-start !important;
      }
    }

    .content-row {
      width: 57% !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .weight-control-list {
        .player-row {
          .content-row {
            .player-name {
              width: 100% !important;
              height: 60px !important;
              padding: 0 0 15px 0 !important;

              > div:not(div.fullname) {
                width: 50px !important;
                height: 50px !important;
                padding: 0 !important;

                img {
                  width: 50px !important;
                  height: 50px !important;
                }
              }
            }
          }
        }
      }

      .player-day {
        height: 37px !important;
        padding: 0 0 15px 0 !important;
      }

      div:not(div:last-child, div.MuiAvatar-circle) {
        padding-bottom: 15px;
        display: flex;
        justify-content: flex-start;
      }

      .options-column {
        width: 100% !important;
        display: flex;
        justify-content: flex-end;
      }

      .optimal-weight {
        height: auto !important;
        justify-content: flex-start !important;

        div {
          padding: 0 !important;
          margin-right: 10px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  // .player-row {
  //   .content-row {
  //     .player-name {
  //       width: 0;
  //     }
  //   }
  // }

  .weight-control-container {
    .training-history-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .training-history-title {
      margin-bottom: 10px !important;

      + div {
        .training-teams-dropdown {
          font-size: 11px !important;
          min-width: 150px;
        }

        button div {
          font-size: 11px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .weight-control-container {
    .weight-control-header {
      margin-bottom: 30px;
      flex-direction: column;
      align-items: flex-start;

      .weight-control-title {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }

  .weight-control-container {
    padding: 20px !important;
  }
}
