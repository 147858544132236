.tfbCheckboxWrapper {
  display: flex;
  gap: 10px;

  input {
    accent-color: green;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
