.prepare-next-match-wrapper {
  .prepare-next-match-container {
    padding: 50px;

    .prepare-next-match-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 40px;
    }
  }
}

.last-trainings-container {
  .last-trainings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 50px;
    .last-trainings-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .training-session-row {
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #666666;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .last-trainings-table-header {
    padding-bottom: 15px;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .training-date {
    width: 30%;
  }

  .training-hour {
    width: 50%;
    text-align: center;
  }

  .training-options {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    .eye-icon-container {
      background-color: #666666;
      width: 35px;
      height: 35px;
      padding: 7px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .position {
    width: 5%;
    text-align: center;
  }
  .player-name {
    width: 65%;
  }
  .player-value {
    width: 30%;
    text-align: center;
  }
}

.unavailable-players-container {
  .unavailable-players-menu {
    height: 50px;
    display: flex;

    .menu-option {
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      cursor: pointer;
      background-color: #fff;
    }
  }

  .injured-players-container {
    padding: 50px;
    background-color: #fafafa;

    .injured-player-row-table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }

    .injured-player-row {
      height: 55px;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .player-name {
      display: flex;
      align-items: center;
      width: 50%;

      .player-avatar {
        margin-right: 15px;
      }
    }

    .injury-zone {
      width: 25%;
      text-align: center;
    }

    .injury-date {
      width: 25%;
      text-align: center;
    }
  }

  .suspended-players-container {
    padding: 50px;
    background-color: #fafafa;
  }
}

.last-direct-match-container {
  .last-direct-match-menu {
    height: 50px;
    display: flex;
    .menu-option {
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      cursor: pointer;
      background-color: #fff;
    }
  }
}

.last-matches-container {
  padding: 50px;
  .last-matches-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
  }

  .last-matches-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .last-matches-param-dropdown {
    background-color: #666666 !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;
    //margin-right: 20px;
    min-width: 225px !important;

    div {
      color: white !important;
      font-size: 14px !important;
    }

    i {
      margin: 0 !important;
    }

    div.visible.menu.transition > div {
      background-color: #666666 !important;
      font-weight: 600;
    }
  }

  .last-matches-list {
    display: flex;
    justify-content: space-between;

    .match-box {
      height: 375px;
      width: 30%;
      background-color: #fafafa;
      position: relative;

      .round-container {
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        font-weight: 700;
        color: #b2b2b2;
      }

      .score-container {
        display: flex;
        margin-top: 25px;

        .logo-container {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 15px;
          padding-right: 15px;

          img {
            height: 125px;
          }
        }
        .score {
          width: 20%;
          font-size: 40px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .date-container {
        text-align: center;
        margin-top: 25px;
        font-size: 16px;
        font-weight: 700;
        color: #b2b2b2;
      }

      .see-more-button {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 50px;
        width: 175px;
        background-color: var(--primaryColor);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .last-matches-players-table {
    .table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }
    .player-row {
      height: 55px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      padding-left: 20px;
      padding-right: 20px;
    }

    .player-name {
      width: 30%;
    }
    .match-value {
      width: 20%;
      text-align: center;
    }
    .average {
      width: 10%;
      text-align: center;
    }
  }
}
