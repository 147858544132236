@media(max-width: 767px){
  .sms-popup-container{
    width: 100% !important;
  }

  .change-password-container{
    width: 100% !important;
  }
}
.change-password-container {
  width: 350px;

  .change-password-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;

    .change-password-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: 700;
    }
  }
  .change-password-row {
    height: 50px;
    margin-bottom: 15px;
    font-weight: 500;
    input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
    }
  }

  .error-row {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: red;
  }

  .change-password-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 20px;
    height: 50px;

    .cancel-button {
      background-color: #666666 !important;
      color: white !important;
      height: 100%;
    }

    .save-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      height: 100%;
    }
  }
}

.sms-popup-container {
  width: 300px;
  padding-left: 15px;
  padding-right: 15px;

  .sms-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 20px;

    .sms-popup-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: 700;
      color: var(--primaryColor);
    }
  }

  .sms-popup-text {
    font-weight: 600;
  }

  .display {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    position: relative;
  }
  .display:last-child {
    border-right: none;
  }

  .wrap {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    display: inline-block;
    position: relative;
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    color: #666666;
    font-weight: 600;
  }

  .input {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
  }

  .shadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 4px var(--primaryColor);
  }

  .sms-popup-footer {
    margin-bottom: 10px;
    .footer-row {
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      .resend-text {
        color: var(--primaryColor);
        padding-left: 5px;
      }
    }
  }

  .sms-popup-error-row {
    text-align: center;
    color: red;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .loader-container {
    text-align: center;
    margin-bottom: 10px;
  }
}

.sms-popup-dialog-box {
  border-radius: 10px;
}

.forgot-pass {
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.forg-title {
  color: var(--primaryColor);
  font-family: "Poppins", sans-serif !important;
  font-weight: 900;
  font-size: 2.2rem !important;
  margin-bottom: 15px;
}