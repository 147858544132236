.sponsors-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 30px;

  .sponsors-header {
    padding-bottom: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sponsors-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .sponsors-contracts-table-header {
    color: #b2b2b2;
    font-size: 13px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    font-weight: 700;
  }

  .sponsors-contracts-table {
    .sponsor-row {
      color: #666666;
      height: 80px;
      display: flex;
      align-items: center;
      font-weight: 700;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 14px;
    }

    .total-row {
      height: 55px;
      background-color: #666666;
      color: white;
      margin-top: 30px;
    }
  }

  .name-column {
    width: 28%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .image-container {
      width: 100px;
      margin-right: 10px;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 40px;
      }
    }
  }
  .common-column {
    width: 12%;
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      height: 10px;
    }

    .eur-icon-sponsor {
      color: #666666;
    }

    .white-eur-icon-sponsor {
      color: white;
    }

    .eur-icon-bonus-sponsor {
      color: var(--primaryColor);
    }

    .arrow-icon-sponsors {
      height: 100%;
      padding-left: 5px;
    }

    .eye-container {
      background-color: #666666;
      width: 35px;
      height: 35px;
      padding: 7px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.sponsor-row-bonuses-container {
  padding: 15px;
}

.sponsor-row-bonuses-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sponsor-row-title {
    font-size: 20px;
    color: black;
    font-weight: 700;
  }
}

.sponsor-row-toggler {
  height: 50px;
  display: flex;
  align-items: center;

  .season-option {
    width: 120px;
    border-radius: 5px;
    background-color: #666666;
    color: white;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .selected-option {
    background-color: var(--primaryColor) !important;
  }
}

.sponsor-row-bonuses-list {
  .sponsor-bonus-row {
    font-weight: 700;
    font-size: 13px;
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding-left: 25px;
    padding-right: 25px;

    .bonus-amount {
      color: var(--primaryColor);
      display: flex;
      align-items: center;
      justify-content: center;

      .eur-icon-bonus-sponsor {
        color: var(--primaryColor);
      }
    }
  }

  .sponsor-bonuses-placeholder {
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
    color: #b2b2b2;
  }
}
//==========================================================================
.sponsor-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .sponsor-name {
    display: flex;
    font-weight: 700;
    font-size: 25px;
    color: black;
  }
}
.sponsor-page-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 30px;
}

.sponsor-bonuses-container {
  padding: 50px;
}

.sponsor-payment-container {
  padding: 50px;
}

.sponsor-payments-list {
  .sponsor-payment-row {
    color: #666;
    height: 55px;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-left: 25px;
    padding-right: 25px;
  }

  .date-column {
    width: 35%;
    text-align: center;
  }

  .value-column {
    width: 30%;
    text-align: center;
  }
}

.sponsor-payment-row-header {
  display: flex;
  color: #b3b3b3;
  font-weight: 600;
  font-size: 13px;
  padding-bottom: 15px;
  margin-top: 50px;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
}

.sponsor-payment-icon {
  color: #666666;
}

.sponsor-last-transactions-container {
  padding: 50px;
  .sponsor-transactions-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
  }

  .transaction-row {
    display: flex;
    min-height: 50px;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    color: #666;
  }

  .transactions-list-header {
    line-height: 31.75px;
    font-weight: 700;
    font-size: 13px;
    color: #b2b2b2;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    padding-bottom: 15px;
  }

  .transaction-description {
    width: 50%;
  }

  .transaction-date {
    width: 25%;
    text-align: center;
  }

  .transaction-amount {
    width: 25%;
    text-align: center;
  }

  .transactions-total-row {
    background-color: #666666;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    height: 55px;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    margin-top: 25px;

    .total-text {
      color: #fff;
      width: 75%;
    }

    .total-amount {
      color: var(--primaryColor);
      width: 25%;
      text-align: center;
    }
  }
}
