.necessity-report-double-row {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;

  .add-necessity-report-form-input {
    width: 50%;
    height: 50px;
  }
}

.add-necessity-report-form-input {
  background-color: #fafafa;
  height: 100%;
  border: 0;
  font-size: 14px !important;
  font-weight: 700;
  color: #666666 !important;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
  border-radius: 3px;
}

.add-contract-container {
  .specification-book-container {
    display: flex;
    height: 50px;
    margin-bottom: 15px;
    align-items: center;
    // background-color: #fafafa;
    position: relative;

    .specification-book {
      font-size: 14px;
      font-weight: 700;
      color: #b3b3b3;
    }

    .currency-container {
      .checkbox-background {
        background-color: #fafafa;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        margin-right: 10px;

        .checked-center {
          position: relative;
          left: 6px;
          top: 6px;
        }
      }
    }
  }

  .necessity-label-button {
    margin-top: 35px;
    margin-bottom: 50px;
    background-color: white;
  }
}

.additional-label-report {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .specification-book-container {
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    // align-items: center;
    // background-color: #fafafa;
    position: relative;

    .specification-book {
      font-size: 14px;
      font-weight: 700;
      color: #b3b3b3;
    }

    .currency-container {
      .checkbox-background {
        background-color: #fafafa;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        margin-right: 10px;

        .checked-center {
          position: relative;
          left: 6px;
          top: 6px;
        }
      }
    }
  }

  .add-contract-row {
    width: 100%;
  }

  .add-necessity-report-form-input {
    background-color: #fafafa;
    height: 100%;
    border: 0;
    font-size: 14px !important;
    font-weight: 700;
    color: #666666 !important;
    padding: 10px;
    padding-left: 20px;
    width: 100%;
    border-radius: 3px;
  }

  .delete-article-necessity-container {
    display: flex;
    height: 50px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    // margin-top: 5px;
    font-weight: 700;

    .ui.button:hover {
      background-color: red !important;
      color: #fafafa !important;
    }

    .ui.button:active {
      background-color: red !important;
      color: #fafafa !important;
    }

    .ui.button:focus {
      background-color: red !important;
      color: #fafafa !important;
    }

    .add-file-button {
      display: flex;
      align-items: center;
      width: 37%;
      height: 50px;
      gap: 12px;
      color: #fafafa;
      border-radius: 5px;
      font-family: "Poppins", sans-serif !important;

      i {
        // margin-left: 20px !important;
        color: white;
      }
    }

    // .add-file-button:hover .add-file-button:active {
    //   background-color: red !important;
    // }

    .cancel-button {
      background-color: red;
      font-weight: 700;
      font-size: 13px;
    }
  }
}
