.add-team-manual-container {
  .add-team-manual-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .add-team-manual-options {
    height: 50px;
    display: flex;

    .add-team-option {
      width: 50%;
      justify-content: center;
      align-items: center;
      display: flex;

      div {
        font-size: 14px;
        font-weight: 700;
        color: #666;
        cursor: pointer;
      }
    }
  }

  .add-team-container-input {
    background-color: #fafafa;
    padding: 25px;

    .add-team-input {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: white;
      color: #666666;
      margin-bottom: 20px;
    }

    .club-team-dropdown {
      select {
        width: 100%;
        margin-bottom: 20px;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: white;
        color: #b2b2b2;

        option {
          padding-left: 25px;
          background-color: white;
          color: #666666;
          font-size: 13px;
        }
      }
    }

    .add-team-manual-image {
      .image-input-container {
        background-color: #fafafa;
        padding-right: 0px;
        width: 100%;
        margin-bottom: 20px;
        span {
          font-size: 14px;
          font-weight: 700;
          color: #666666;
        }
      
        input {
          opacity: 0;
          position: absolute;
          z-index: -1;
          overflow: visible;
          width: 2px;
        }
      
        label {
          font-weight: 700;
          font-size: 14px;
          color: #b2b2b2;
          padding-left: 0px;
          cursor: pointer;
          width: 100px;
          height: 100px;
          display: block;
          margin: 0 auto !important;
          .image-view {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 0.5px solid #d2d2d2;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 80%;
              height: auto;
              max-height: 80%;
              width: auto;
            }
            span {
              font-size: 12px;
              display: flex;
              width: 100%;
              align-items: center;
              height: 100%;
              text-align: center;
              justify-content: center;
              i {
                font-size: 20px !important;
              }
            }
          }
        }

      }
    }
    
    .add-team-manual-buttons {
      display: flex;
      justify-content: space-between;

      .add-team-button {
        background-color: var(--primaryColor) !important;
        height: 50px;
        width: 47%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
      }

      .cancel-button {
        background-color: #666666 !important;
      }

      .white-icon {
        color: white;
        padding-left: 20px;
        opacity: 1 !important;
      }
    }
  }
}
