.bonuses-page-container {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;

  .bonuses-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .bonuses-title {
      font-weight: 700;
      font-size: 20px;
    }

    .bonus-button {
      margin-right: 20px;
      background-color: var(--primaryColor);
      color: white;
      font-weight: 700;
    }
  }
}

.white-icon {
  color: white !important;
  padding-left: 10px;
  opacity: 1 !important;
}

.dark-icon {
  color: #666666 !important;
  opacity: 1 !important;
}
