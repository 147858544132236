.payments-container {
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;

  .payments-header {
    display: flex;
    // justify-content: space-between;
    // align-items: center;

    .payments-header-title {
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.payment-value-type-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;

  .payment-text {
    font-size: 10px;
    text-transform: none;
    font-weight: 300;
  }
}

.payments-header-title {
  font-size: 20px;
  font-weight: 700;
}

.add-payment-row {
  height: 50px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  display: flex;

  .add-payment-receipt-serial {
    width: 100%;
    height: 50px;
    input {
      background-color: #fafafa !important;
      padding-left: 24px !important;
      border: 0 !important;
      font-family: "Poppins" !important;
      font-weight: 900 !important;
    }

    ::placeholder {
      color: #b2b2b2 !important;
    }
  }

  .text-area-add-event {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding-left: 20px;
    padding-top: 10px;
    background-color: #fafafa !important;
  }

  .date-input {
    width: 100%;
    height: 50px;
    background-color: #fafafa;

    div {
      color: #b2b2b2;
      font-weight: 700;
      padding-left: 5px;
    }

    fieldset {
      border-color: #fafafa;
    }
  }
  .calendar-icon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .input-text {
    width: 100%;
    height: 100%;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #666666;
  }

  select {
    width: 100%;
    height: 100%;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #666666;

    option {
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
      font-size: 13px;
    }
  }
}

.close-icon-popup {
  cursor: pointer;
  font-size: 20px !important;
  margin: auto;
}

.add-payment-header {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 40px;

  .add-payment-title {
    font-size: 20px;
    font-weight: 700;
    margin-right: 30px;
  }
}

.white-icon {
  color: white;
  opacity: 1 !important;
}

.display-payment-row {
  font-size: 14px;
  font-weight: 700;
  padding: 5px 0px 5px 0px;
  // background-color: #fafafa;
  color: #666666;
}

@media (max-width: 991px) {
  .payments-header {
    display: flex;
    justify-content: flex-end !important;
  }

  .payments-container {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;

    .payments-header {
      display: flex;
      // justify-content: space-between;
      // align-items: center;

      .payments-header-title {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}

.team-payments-navigate-year-container {
  display: flex;
  gap: 10px;
  .year-text {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 768px) {
  .team-payments-navigate-year-container {
    align-self: center;
  }
}

.rdt_TableCell:first-child,
.rdt_TableCol:first-child {
  background-color: #fafafa;
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
}

.edit-payments-pop-up-payments-list {
  margin-top: 30px;
  .title {
    font-size: 18px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
}
