.clients-header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;

  .clients-table-title {
    font-size: 20px;
    font-weight: 700;
  }

  .clients-header-right {
    display: flex;
  }
}

.clients-table-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  .name-column {
    width: 30%;
    cursor: pointer;
  }
  .sum-column {
    width: 25%;
    display: flex;
    justify-content: center;

    .currency-icon {
      color: #666666;
    }
  }
  .date-column {
    width: 25%;
    text-align: center;
  }

  .total-column {
    width: 25%;
    text-align: center;

    .currency-icon {
      color: #666666;
    }
  }
  .actions-column {
    width: 20%;
    justify-content: flex-end;
    display: flex;

    .svg-icon-clients {
      padding: 5px;
      cursor: pointer;
    }
  }

  .clients-table-header {
    display: flex;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .clients-list {
    .client-row {
      color: #666666;
      height: 55px;
      display: flex;
      align-items: center;
      font-weight: 700;
      padding-left: 15px;
      padding-right: 15px;
    }

    .client-total-row {
      display: flex;
      justify-content: center;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.add-client-button {
  margin-left: 10px !important;
  background-color: var(--primaryColor) !important;
  color: white !important;
  .client-button-content {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
  }
}

.add-new-client-container {
  .sb-row {
    display: flex;
    justify-content: space-between;
  }
  .form-row-add-client {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 400px;

    .currency-selector {
      display: flex;
      margin-left: 20px;
      .currency {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }

  .or-row {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
  }

  .buttons-container-add-client {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;

    .add-client-cancel-button {
      background-color: #666666 !important;
      color: white !important;
      width: 47%;
      height: 50px;
      margin: 0 !important;

      .op-cancel-icon {
        color: white !important;
      }
    }

    .add-client-save-button {
      background-color: #00d948 !important;
      color: white !important;
      width: 47%;
      height: 50px;
      margin: 0 !important;
    }
  }
}

.client-delete-popup {
  .row-1 {
    text-align: center;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .row-2 {
    text-align: center;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .row-3 {
    text-align: center;
    font-weight: 600;
    justify-content: center;
    display: flex;
  }

  .delete-popup-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;

    .delete-popup-button-cancel {
      width: 47%;
      background-color: #666666 !important;
      color: white !important;
    }
    .delete-popup-button-delete {
      width: 47%;
      background-color: red !important;
      color: white !important;
    }
  }
}
