.events-card {
  .tfb-button-custom {
    display: flex;
    justify-content: center;
  }
  .tfb-button-custom {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 476px) {
  .events-card {
    .tfb-card-custom {
      background-color: #fafafa !important;
    }

    .tfb-button-custom {
      height: 35px;
      display: flex;
      align-items: center;
    }

    .time-setting-container {
      display: flex;
      gap: 20px;

      //times-setter
      .start-moment,
      .end-moment {
        display: flex;
        align-items: flex-end;
        gap: 12px;

        .MuiInputBase-root {
          height: 35px !important;
        }
      }
    }

    .event-player-container {
      display: flex;
      gap: 12px;
      align-items: flex-end;
      margin-top: 20px;

      .event-type,
      .event-player {
        .MuiFormControl-root {
          background-color: white;
          .MuiInputBase-root {
            height: 35px;
          }
        }
      }
    }

    .add-more-events {
      margin-top: 20px;
    }

    .event-title,
    .event-details {
      margin-top: 20px;

      .MuiFormControl-root {
        background-color: white;
        .MuiInputBase-root {
          height: 35px;
          font-weight: 700;
        }
      }
    }

    .add-event-buttons {
      margin-top: 30px;
      display: flex;
      gap: 20px;

      .tfb-button-custom {
        width: 100%;

        div {
          width: 100%;
          justify-content: space-between;
        }
      }
    }

    .white-bar-horizontal {
      margin: 50px 0px;
      border-bottom: 1px solid black;
    }

    .events-list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .event-itself {
        display: flex;
        //   justify-content: space-between;
        align-items: center;
        background-color: white;
        min-height: 69px;

        .details {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .title {
            font-family: "Poppins";
            font-weight: 700;
            font-size: 14px;
            //   line-height: 10px;
          }

          .exact-time {
            font-family: "Poppins";
            font-weight: 700;
            font-size: 13px;
            color: #b2b2b2;
            //   line-height: 9px;
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-left: auto;
          margin-right: 20px;
        }
      }
    }

    .generate-highlights {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      align-items: center;
      justify-content: space-between;

      .MuiFormControl-root {
        background-color: white;
        width: 55%;
        .MuiInputBase-root {
          height: 35px;
          font-weight: 700;
        }
      }
    }
  }
}

@media only screen and (max-width: 476px) {
  .events-card {
    .tfb-card-custom {
      background-color: #fafafa !important;
    }

    .tfb-button-custom {
      height: 35px;
      display: flex;
      align-items: center;
    }

    .time-setting-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      //times-setter
      .start-moment,
      .end-moment {
        display: flex;
        align-items: flex-end;
        gap: 12px;

        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            height: 35px !important;
          }
        }
      }
    }

    .event-player-container {
      display: flex;
      gap: 12px;
      align-items: flex-end;
      margin-top: 20px;

      .event-type,
      .event-player {
        .MuiFormControl-root {
          background-color: white;
          .MuiInputBase-root {
            height: 35px;
          }
        }
      }
    }

    .add-more-events {
      margin-top: 20px;
    }

    .event-title,
    .event-details {
      margin-top: 20px;

      .MuiFormControl-root {
        background-color: white;
        .MuiInputBase-root {
          height: 35px;
          font-weight: 700;
        }
      }
    }

    .add-event-buttons {
      margin-top: 30px;
      display: flex;
      gap: 20px;

      .tfb-button-custom {
        width: 100%;

        div {
          width: 100%;
          justify-content: space-between;
        }
      }
    }

    .white-bar-horizontal {
      margin: 50px 0px;
      border-bottom: 1px solid black;
    }

    .events-list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .event-itself {
        display: flex;
        //   justify-content: space-between;
        align-items: center;
        background-color: white;
        min-height: 69px;

        .details {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .title {
            font-family: "Poppins";
            font-weight: 700;
            font-size: 14px;
            //   line-height: 10px;
          }

          .exact-time {
            font-family: "Poppins";
            font-weight: 700;
            font-size: 13px;
            color: #b2b2b2;
            //   line-height: 9px;
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-left: auto;
          margin-right: 20px;
        }
      }
    }

    .generate-highlights {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      align-items: center;
      justify-content: space-between;

      .MuiFormControl-root {
        background-color: white;
        width: 55%;
        .MuiInputBase-root {
          height: 35px;
          font-weight: 700;
        }
      }
    }
  }
}
