.delete-team-section-image-popup-container {
  padding: 26px;

  .delete-team-section-image-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    .delete-team-section-image-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .delete-team-section-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-team-section-buttons {
    height: 50px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    .cancel-button {
      color: #fafafa !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
      i {
        color: white !important;
      }
    }

    .delete-team-section-button {
      background-color: #db2828 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: #fafafa !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
      i {
        color: white !important;
      }
    }
  }
}

.training-section-actions-wrapper {
  gap: 5px;
  display: flex;
  justify-content: flex-end;
}

.training-section-progress-bar-wrapper {
  display: flex;
  justify-content: center;

  .training-section-progress-bar {
    height: 50px;
    width: 50px;
    display: flex;
  }
}

.training-sections-add-button {
  display: flex;
  justify-content: center;
}

.delete-team-section-video-popup-container {
  padding: 26px;

  .delete-team-section-video-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    .delete-team-section-video-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .delete-team-section-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-team-section-buttons {
    height: 50px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    .cancel-button {
      color: #fafafa !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
      i {
        color: white !important;
      }
    }

    .delete-team-section-button {
      background-color: #db2828 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: #fafafa !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
      i {
        color: white !important;
      }
    }
  }
}

.section-training-dialog {
  max-width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  // padding-bottom: 26px;
  .image-in-dialog {
    padding-bottom: 26px;
    max-width: inherit;
  }

  .section-training-dialog-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-left: 12px;
    padding-bottom: 10px;
    .section-training-dialog-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }
  }
}

.training-history-container.plan-training-list-container {
  .no-training-call-up-message {
    text-align: center;
    .no-training-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.training-history-list .training-row-table-header {
  display: flex;
}

.training-container .training-row-table-header {
  display: none !important;
}

// .match-costs-edit-header {
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   .arrow-back-nav {
//     margin-bottom: 50px;
//     cursor: pointer;
//   }
// }

.add-training-page-title {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 25px;
  color: black;
}

.plan-training-container {
  // padding-top: 65px;
  // padding-bottom: 65px;
  // padding-left: 50px;
  // padding-right: 50px;

  .plan-training-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    height: 50px;
    // align-items: center;

    .plan-training-title {
      font-weight: 700;
      font-size: 20px;
      color: black;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .add-section-button {
      background-color: var(--primaryColor) !important;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      min-height: 50px;
      min-width: 230px !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      .add-section-button-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }

  .plan-training-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 25px;
    color: black;
    .add-training-row select {
      margin-bottom: -12px;
      margin-top: 20px;
    }
  }

  .plan-training-subtitle {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 700;
    color: black;
  }

  .plan-training-final-drop-area {
    border: 2px dashed #ededed;
    position: relative;
    margin-bottom: 65px;
    padding: 25px;
    display: flex;
    flex-direction: column;

    .sections-list {
      min-height: 175px;
      margin-bottom: 15px;
    }

    .plan-training-final-drop-row {
      display: flex;
      height: 50px;
      margin-bottom: 15px;
      justify-content: space-between;

      .final-drop-row-name {
        width: 70%;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        color: #666666;
        font-size: 14px;
        font-weight: 700;

        .delete-icon-section-row {
          color: #666666;
        }
      }

      .wrong-effective-duration {
        border: 1px solid red !important;
      }

      .final-drop-row-input {
        width: 25%;
        background-color: #fafafa;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: #fafafa;
        color: #666;
      }
    }

    .final-drop-area-total-row {
      width: 100%;
      background-color: #666666;
      height: 55px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 700;

      .total-text {
        color: white !important;
      }

      .total-value {
        color: var(--primaryColor) !important;
      }
    }

    .final-drop-area-placeholder {
      font-size: 14px;
      color: #b2b2b2;
      font-weight: 700;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
    }
  }

  .plan-training-text-area {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
  }

  .plan-training-row {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .plan-training-hour-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .plan-training-date-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }
  }

  .plan-training-error-row {
    color: red;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }

  .add-training-save-button {
    background-color: #00d948 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    color: white !important;
    height: 50px;
    width: 100%;
  }

  .plan-training-error-row {
    text-align: center;
    color: red;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .add-section-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;

    .add-section-input {
      width: 80%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .add-section-options {
      display: flex;
      align-items: center;
      .add-section-option-button {
        width: 50px;
        height: 50px;
        background-color: #666666;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .add-section-option-icon {
          opacity: 1 !important;
          color: white;
          margin: 0 !important;
        }
      }
    }
  }

  .existing-sections-list {
    display: flex;
    flex-wrap: wrap;
    .existing-section-container {
      background-color: #fafafa;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      height: 50px;
      border-radius: 3px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      width: min-content;
      white-space: nowrap;
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 10px;
    }
  }
}

.add-training-row {
  select {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 18px;
    background-color: #fafafa;
    color: #b2b2b2 !important;
    margin-bottom: 15px;

    option {
      padding-left: 25px;
      background-color: #fafafa;
      color: #b2b2b2;
      font-size: 13px;
    }
  }
}

.training-history-container.plan-training-list-container {
  .plan-training-header {
    > div:last-child {
      display: flex;
    }
  }
  .add-training-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
  }
}

.add-venues-input {
  input {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 25px;
    background-color: #fafafa;
    color: #b2b2b2;
  }
}

.add-venues-dropdown {
  width: 100%;
  select {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #b2b2b2;

    option {
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
      font-size: 13px;
    }
  }
}

// .training-history-container {
//   padding: 50px;

.training-history-header {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  .training-history-title {
    font-size: 20px;
    font-weight: 700;
  }

  .training-teams-dropdown {
    background-color: #666666 !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 200px !important;

    .divider.default.text {
      color: white;
    }

    i {
      top: 36% !important;
      color: white;
    }

    .divider.text {
      color: white;
    }
  }
}

.training-row-table-header {
  display: flex;
  color: #b3b3b3;
  font-weight: 600;
  font-size: 13px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
}

.training-row {
  min-height: 85px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #666666;
  padding-left: 25px;
  padding-right: 25px;
}

.section-column {
  width: 25%;
}

// .date-column {
//   width: 20%;
//   display: flex;
//   justify-content: center;
//   text-align: center;

.training-schedule-video {
  display: flex;
  justify-content: center;
  display: flex;

  .training-video-thumbnail:hover {
    -webkit-filter: brightness(1);
    filter: brightness(1);
  }
  .training-video-thumbnail {
    width: 88px;
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
    cursor: pointer;
  }
  .thumbanail-play-button {
    position: relative;
    top: 17px;
    left: 55px;
    color: white;
    z-index: 3;
    cursor: pointer;
  }
  .thumbanail-play-button:hover + .training-video-thumbnail {
    -webkit-filter: brightness(1);
    filter: brightness(1);
  }

  .delete-video-icon {
    position: relative;
    left: 6px;
    top: 20px;
  }

  .delete-video-icon:hover {
    color: #db2828 !important;
    cursor: pointer;
  }
}

.training-section-plus-button {
  display: flex;
  justify-content: center;
  background-color: #666666;
  width: 35px;
  height: 35px;
  padding: 7px;
  border-radius: 5px;
  position: relative;
  right: 8px;
  cursor: pointer;

  i {
    position: relative;
    right: 2px;
  }
}

.training-schedule-image {
  display: flex;
  justify-content: center;
  i {
    position: relative;
    // left: 23px;
    top: 18px;
  }
  i:hover {
    color: #db2828 !important;
    cursor: pointer;
  }

  .image-training-schedule:hover {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
    cursor: pointer;
  }
}

.training-section-plus-button-video {
  display: flex;
  justify-content: center;
  background-color: #666666;
  width: 35px;
  height: 35px;
  padding: 7px;
  border-radius: 5px;
  position: relative;
  right: 4px;
  cursor: pointer;

  i {
    position: relative;
    right: 2px;
  }
}

// .options-column {
//   width: 15%;
//   display: flex;
//   align-items: center;
//   justify-content: right;
//   gap: 8px;

//   .training-section-option-buttons {
//     background-color: #666666;
//     width: 35px;
//     height: 35px;
//     padding: 7px;
//     border-radius: 5px;
//     cursor: pointer;
//   }
//   .trash-icon-container:hover {
//     background-color: #db2828;
//   }
// }

// .training-row-table-header.mobile-header {
//   padding: 0 !important;
// }
// }

.add-section-training-form {
  // min-width: 400px;
  padding-left: 26px;
  padding-right: 26px;

  .add-section-training-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 30px;

    .add-section-training-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }
  }

  .add-section-training-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    position: relative;

    .add-section-training-input {
      background-color: #fafafa;
      height: 100%;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding-left: 20px;
      width: 100%;
      border-radius: 3px;
    }

    .add-section-training-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;
      width: 100%;

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      input {
        display: flex;
        height: 50px;
        align-items: center;
        padding-left: 20px;
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }
    }
  }

  .add-section-training-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 50px;

    .add-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      height: 100%;
    }
  }
}

//Delete section-training popup

.delete-section-training-popup-container {
  .delete-section-training-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .delete-section-training-title {
      font-size: 20px;
      font-weight: 700;
    }

    .close {
      cursor: pointer;
    }
  }

  .delete-section-training-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-section-training-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;

    .cancel-button {
      color: #fafafa !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }

    .delete-comp-button {
      background-color: #db2828 !important;
      // background-color: #666666 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: #fafafa !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }

    .dark-icon {
      color: #666666;
    }

    .grey-icon {
      color: #fafafa;
    }
  }
}

@media only screen and (min-width: 992px) {
  .training-history-container.plan-training-list-container {
    .add-training-button {
      // background-color: var(--primaryColor) !important;
      // color: white !important;
      // font-family: "Poppins", sans-serif !important;
      // font-weight: 700;
      // height: 50px;
      margin-right: 10px;
    }
  }

  .desktop-header {
    display: flex !important;
  }

  .header-row {
    display: none !important;
  }

  .content-row {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {
  .training-history-container.plan-training-list-container {
    .add-training-button {
      // background-color: var(--primaryColor) !important;
      // color: white !important;
      // font-family: "Poppins", sans-serif !important;
      // font-weight: 700;
      // height: 50px;
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  // .presence-history-container {
  //   padding: 5px !important;
  // }

  //////
  .presence-report-training-interactive {
    display: flex;
    flex-direction: column;
    gap: 0px !important;

    .training-teams-dropdown {
      width: 100% !important;
    }

    // .presence-history-container {
    //   padding: 0px !important;
    // }

    .presence-training-date-pickers {
      height: 50px;
      width: 100% !important;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;

      .calendar-icon {
        display: none !important;
      }
    }

    .show-presence-button {
      width: 100%;
    }
  }
  /// //////

  .desktop-header {
    display: none !important;
  }

  .training-row {
    // height: 230px !important;
    padding: 20px !important;
  }

  .training-row-table-header.header-row {
    padding: 0 !important;
  }

  .header-row {
    display: block;
    width: 50%;
    height: 100%;
  }

  .mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    div {
      width: 100% !important;
      height: 37px !important;
      display: flex;
      justify-content: flex-start;
    }
  }

  .training-row {
    .content-row {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div:not(div:last-child, .optimal-weight div) {
        width: 100% !important;
        height: 45px !important;
        padding-bottom: 15px;
        display: flex;
        justify-content: flex-start;
      }

      .options-column {
        width: 100% !important;
        display: flex;
        justify-content: flex-end;
      }

      .player-options {
        width: 100% !important;
        justify-content: flex-start !important;

        .add-weight-button-container {
          width: 100% !important;
          height: 35px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .plan-training-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .training-history-header .plan-training-header {
    .training-history-title {
      margin-bottom: 10px;
    }
  }

  .planning-training-interactive {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .plan-training-title {
    margin-bottom: 50px;

    + div {
      .training-teams-dropdown {
        font-size: 11px !important;
        min-width: 150px !important;
      }

      button div {
        font-size: 11px !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }
}
