.checkbox-header {
  min-width: 101px !important;

  label {
    color: white !important;
  }
}

.presence-training-detail-mark {
  .text-input {
    height: 38px;
    min-width: 220px;
    width: 90%;
    border: solid 1px hsl(0deg 0% 80%);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    background-color: transparent;
    color: #666;
  }
}

.training-details-container {
  // margin: 30px;
  .training-details-page-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .present-list-button {
      background-color: #666666 !important;
      color: white !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;

      .button-content {
        display: flex;
        align-items: center;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px;
      }
    }
  }
  .training-table {
    margin-bottom: 40px;
    .table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-bottom: 15px;
      font-family: "Poppins", sans-serif !important;
      .col1 {
        display: flex;
        align-items: center;
      }
      .name {
        width: calc((85% - 180px) / 2);
      }
      .present-check {
        width: 15%;
        padding: 0 8px;
        label {
          color: #b3b3b3;
          font-weight: 600;
          font-size: 13px;
          font-family: "Poppins", sans-serif !important;
          margin-bottom: 0 !important;
        }
      }
      .remarks {
        width: calc((85% - 180px) / 2);
      }
      .qualifying {
        width: 180px;
        .select-mark {
          width: 150px;
        }
        label {
          color: #b3b3b3;
          font-weight: 600;
          font-size: 13px;
          font-family: "Poppins", sans-serif !important;
        }
      }
    }
    .table-content {
      .table-row {
        display: flex;
        height: 60px;
        align-items: center;
        font-weight: 700;
        color: #666666;
        padding-left: 15px;
        .name {
          width: calc((85% - 180px) / 2);
          display: flex;
          gap: 15px;
          align-items: center;
          cursor: pointer;

          .player-error-container {
            .error-not-pay {
              color: red;
              font-size: x-small;
            }
          }
        }
        .present-check {
          width: 15%;
          padding: 0 8px;
        }
        .remarks {
          width: calc((85% - 180px) / 2);
          .text-input {
            height: 38px;
            width: 80%;
            border: solid 1px hsl(0deg 0% 80%);
            border-radius: 5px;
            font-size: 14px;
            font-weight: 700;
            padding-left: 10px;
            background-color: transparent;
            color: #666;
          }
        }
        .qualifying {
          width: 180px;
          .select-mark {
            width: 150px;
          }
        }
      }
    }
  }
  .training-table-mobile {
    display: none;
  }

  .buttons-container {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    .style-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      width: 180px;

      .style-button-icon {
        color: white;
        opacity: 1 !important;
      }
      .style-button-icon-dark {
        color: #666666;
        opacity: 1 !important;
      }
    }

    .dark-background {
      background-color: #666666 !important;
    }

    .light-background {
      background-color: #d4d4d4 !important;
      color: #666666 !important;
    }
  }
}

@media only screen and (max-width: 650px) {
  .training-details-container {
    // margin: 3%;
    .buttons-container {
      width: 100%;
      .style-button {
        width: 100%;
      }
    }
    .training-table,
    .presence-detail-training-table-desktop {
      display: none;
    }
    .training-table-mobile {
      display: block;
      font-weight: 600;
      font-size: 14px;
      font-weight: 600;
      font-family: "Poppins", sans-serif !important;
      .table-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        font-size: 14px;
        .present-check {
          .button {
            font-family: "Poppins", sans-serif !important;
            min-height: 38px;
            font-size: 14px !important;
            color: #b3b3b3;
            background-color: white;
            border: 1px solid #b3b3b3;
            border-radius: 5px;
            font-weight: 600;
            .check-custom {
              margin: 0 10px 0 0 !important;
            }
          }
        }
        .qualifying {
          .select-mark {
            width: 132px;
          }
        }
      }
      .table-content {
        .table-card {
          background-color: #fafafa;
          padding: 10px;
          border-radius: 10px;
          margin-bottom: 15px;
          font-size: 14px;

          .remark {
            width: 100%;
            .text-input {
              font-family: "Poppins", sans-serif !important;
              width: 100%;
              min-height: 38px;
              border: 1px solid #b3b3b3;
              color: #b3b3b3;
              border-radius: 5px;
              padding-left: 10px;
              background-color: white;
              font-weight: 600;
            }
          }

          .card-row {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 10px;
            width: 100%;

            .present-plus-mark {
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 10px;
              margin-bottom: 10px;
            }

            .player {
              display: flex;
              align-items: center;
              font-size: 18px;
              color: black;
              gap: 10px;
              .player-photo {
                .player-avatar {
                  width: 55px;
                  height: 55px;
                }
              }
              .player-error-container {
                .error-not-pay {
                  color: red;
                  font-size: x-small;
                }
              }
            }
            .present {
              display: flex;
              gap: 10px;
              align-items: center;
              color: #b3b3b3;
              font-size: 16px;
              .check-custom {
                transform: scale(1.5);
              }
            }
            .mark-row {
              width: calc(100% - 120px);
              .select-mark {
                width: 100%;
              }
            }
            .remark {
              width: calc(100% - 95px);

              .text-input {
                font-family: "Poppins", sans-serif !important;
                width: 100%;
                min-height: 38px;
                border: 1px solid #b3b3b3;
                color: #b3b3b3;
                border-radius: 5px;
                padding-left: 10px;
                background-color: white;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
    }
  }
}

//Delete player-training popup

.delete-player-training-popup-container {
  .delete-player-training-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .delete-player-training-title {
      font-size: 20px;
      font-weight: 700;
    }

    .close {
      cursor: pointer;
    }
  }

  .delete-player-training-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-player-training-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;

    .cancel-button {
      color: #fafafa !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }

    .delete-comp-button {
      background-color: #db2828 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: #fafafa !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }

    .add-file-button {
      background-color: var(--primaryColor) !important;
      width: 47%;
      font-family: "Poppins", sans-serif !important;
      color: white !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 700;
      height: 100%;

      .add-file-button-icon {
        opacity: 1 !important;
        color: white;
      }
    }

    .dark-icon {
      color: #666666;
    }

    .grey-icon {
      color: #fafafa;
    }
  }
}
