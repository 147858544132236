.import-csv-page-container {
  padding: 50px;

  .rdt_TableCol {
    display: flex;
    justify-content: center !important;
    //   align-self: center !important;
  }

  .import-csv-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
  }

  .import-csv-row {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 15px;

    .import-csv-row-text {
      font-size: 14px;
      font-weight: 700;
      color: #666666;
    }

    .step-label {
      font-size: 20px;
      color: #666666;
      font-weight: 700;
      margin-right: 20px;
    }

    .import-csv-input {
      width: 350px;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
      margin-left: 20px;
    }
  }

  .import-csv-input-group {
    height: 100%;
    background-color: #fafafa;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    font-size: 13px;

    .add-file-form-file-input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .add-file-form-file-label {
      font-weight: 700;
      font-size: 14px;
      color: #666666;
      padding-left: 20px;
      cursor: pointer;
      margin-bottom: 0 !important;
    }
  }

  .import-csv-rows-list {
    padding-left: 50px;
    .csv-row {
      display: flex;
      align-items: center;
      height: 50px;
      .checkbox {
        margin-right: 15px;
      }

      .csv-row-name {
        font-size: 14px;
        color: #666666;
        font-weight: 700;
      }

      .csv-row-dropdown {
        margin-left: 20px;
      }
    }
  }

  .import-csv-button {
    height: 50px;
    background-color: var(--primaryColor) !important;
    font-family: Poppins, sans-serif !important;
    font-size: 13px !important;
    color: #fff !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    min-width: 250px;

    .import-csv-icon {
      opacity: 1 !important;
      color: white;
    }
  }

  .import-csv-button-cancel {
    height: 50px;
    background-color: #666666 !important;
    font-family: Poppins, sans-serif !important;
    font-size: 13px !important;
    color: #fff !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 9%;

    .import-csv-icon {
      opacity: 1 !important;
      color: white;
    }
  }
}

.data-extras-cont-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px 0px 10px 0px;

  .data-extras-cont {
    justify-content: center;
    display: flex;
  }

  .select-player-extras-cont {
    display: flex;
    flex-direction: column;
    width: 220px;
    flex-wrap: nowrap;

    .MuiInputBase-root {
      height: 40px;
    }
  }
}

.extras-cont-checkbox {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
}
