.tfbInput {
  fieldset {
    border: none;
  }
}

.inputLabel {
  color: #b2b2b2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}

.inputGroup {
  width: 100%;
}
