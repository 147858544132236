.tfbicon {
}
.green {
  filter: invert(62%) sepia(91%) saturate(1628%) hue-rotate(89deg) brightness(94%) contrast(102%);
}
.white {
  filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);
}
.black {
  filter: invert(0%) sepia(93%) saturate(0%) hue-rotate(284deg) brightness(107%) contrast(107%);
}
.gray {
  filter: invert(40%) sepia(12%) saturate(5%) hue-rotate(342deg) brightness(95%) contrast(92%);
}
.red {
  filter: invert(21%) sepia(81%) saturate(6782%) hue-rotate(355deg) brightness(113%) contrast(121%);
}
