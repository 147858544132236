.rdt_TableCol {
    padding: 0 8px !important;
}
.rdt_TableCol_Sortable {
    text-align: center;
}
div[data-tag="allowRowEvents"] {
    text-transform: uppercase;
}
.cards-management {
    .rdt_TableHeader {
        display: none;
    }

    .cards-management-generate{
        margin-bottom: 30px;
    }
}
