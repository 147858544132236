//PLAYER CONTRACT WIDGET ==========================================================================
.staff-info-widget {
  .staff-profile-img {
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
}

.edit-player-details-date {
  .MuiInputBase-input.MuiInput-input {
    height: auto;
  }
}

.width48 {
  width: 48% !important;
}

.no-access-placeholder-staff {
  text-align: center;
  padding: 15px;
  // position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 700;

  .no-access-text {
    font-size: 17px;
    padding-top: 10px;
  }
}

@media (max-width: 1520px) {
  .col-lg-2 {
    max-width: 100% !important;
    margin-bottom: 20px;
    padding-top: 50px;
    .ui.card {
      width: 100% !important;
    }
  }

  .col-lg-10 {
    max-width: 100% !important;
    .col-lg-5 {
      max-width: 100% !important;
      margin-bottom: 20px;
    }

    .col-lg-7 {
      max-width: 100% !important;
    }
  }

  // div[name="selected_season"] {
  //   position: absolute;
  //   top: 110px;
  //   right: 17px;
  // }
}

@media (max-width: 600px) {
  .staff-profile-contract {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .instat-no-display-mobile {
    display: none !important;
  }

  .advanced-stats-container {
    display: none !important;
  }

  .dropdown-season-select {
    padding-left: 12px !important;
    margin-bottom: 12px !important;

    div[name="selected_season"] {
      margin-bottom: 0px !important;
    }
  }

  .contract-info-staff-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .contract-info-title-container {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      .contract-info-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 31.75px;
      }
    }
  }

  .widget {
    padding-top: 0px !important;
  }
  .player-transactions {
    // padding: 30px 50px;
    // height: 100%;

    .transaction-row {
      display: flex;
      min-height: 50px;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      color: #666666;
    }

    .transactions-list-header {
      line-height: 31.75px;
      font-weight: 700;
      font-size: 13px;
      color: #b2b2b2;
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      padding-bottom: 15px;
    }
    .transactions-list {
      max-height: 350px;
      overflow: auto;

      .transaction-description {
        width: 33% !important;
      }

      .transaction-date {
        width: 33% !important;
        text-align: center;
      }

      .transaction-amount {
        width: 33% !important;
        text-align: center;
      }
    }
  }

  .no-margin-bottom {
    margin-bottom: 0px !important;
  }

  .player-profile-injury-container {
    height: 100%;
    flex-direction: column;
    .injuries-table-container {
      padding: 20px;
    }
  }

  .basic-info-row {
    height: 100%;
  }

  .player-transactions-title {
    padding-left: 30px;
  }

  .title-highlights-container {
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .event-type-select {
      select,
      input.event-type {
        height: 44px !important;
        background-color: #fafafa !important;
        color: #666666 !important;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        width: 200px;
        padding: 10px;
      }
    }
  }

  .training-table {
    .training-header-row {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 0px !important;
      padding-bottom: 15px;
      font-family: "Poppins", sans-serif !important;
    }

    .training-date {
      width: 29% !important;
      justify-content: start;
    }
    .training-present {
      width: 29% !important;
    }
    .training-raiting {
      width: 13% !important;
    }
    .training-comment {
      width: 29% !important;
    }
  }

  .training-row {
    .training-date {
      width: 29% !important;
      justify-content: start;
    }
    .training-present {
      width: 29% !important;
    }
    .training-raiting {
      width: 13% !important;
    }
    .training-comment {
      width: 29% !important;
    }
  }

  .min-width-overwrite {
    min-width: 30% !important;
  }
}

@media (max-width: 767px) {
  .add-player-form {
    min-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .edit-team-name-popup {
    width: 100%;
  }

  .delete-staff-popup-container,
  .edit-player-details-container {
    width: 100% !important;
  }

  .edit-player-details-container {
    .edit-details-row:nth-child(3) {
      flex-direction: column;
      height: 100%;
      .edit-player-details-dropdown {
        width: 100%;
        margin-bottom: 20px;
      }

      .edit-player-details-input {
        width: 100% !important;
        height: 50px;
      }
    }
  }
}

.contract-info-staff-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  .contract-info-title-container {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    .contract-info-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 31.75px;
    }
  }

  .contract-info-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 31.75px;
    padding-bottom: 10px;
  }

  .active-contract-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 50px;
    font-weight: 700;
    font-size: 13px;
    color: #b2b2b2;

    .active-contract-date {
      width: 35%;
      text-align: center;
    }

    .active-contract-wage {
      width: 30%;
      text-align: center;

      .contract-currency-icon {
        color: #b2b2b2;
      }
    }
  }

  .bonuses-container {
    width: 100%;
    // max-height: 165px;
    overflow-y: auto;

    .contract-widget-bonus {
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      font-size: 13px;
      align-items: center;
      font-weight: 700;

      .bonus-title {
        color: #b2b2b2;
        padding-left: 10px;
        padding-right: 20px;
      }

      .bonus-element {
        color: #666666;
        display: flex;
      }
    }
  }
}

.placeholder-bonuses {
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}

// PLAYER PROFILE MEDICAL =======================================================================

.profile-medical-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  .profile-medical-title {
    display: flex;
    align-items: center;
    div {
      font-size: 20px;
      font-weight: 700;
      line-height: 31.75px;
      margin-left: 5px;
    }
  }

  .profile-injuries-dropdown {
    background-color: #666666 !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;

    div {
      color: white !important;
      font-size: 14px !important;
    }

    i {
      margin: 0 !important;
    }

    div.visible.menu.transition > div {
      background-color: #666666 !important;
      font-weight: 600;
    }
  }
}

//REPORTS CHARTS

.chart-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 40px;

  .chart-card-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 31.75px;
  }

  .profile-chart-dropdown {
    background-color: #666666 !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;

    div {
      color: white !important;
      font-size: 14px !important;
    }

    i {
      margin: 0 !important;
    }

    div.visible.menu.transition > div {
      background-color: #666666 !important;
      font-weight: 600;
    }
  }
}

.chart-card-menu {
  display: flex;
  padding-left: 50px;
  .chart-card-menu-option {
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .option-text {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.profile-test-chart-container-graph {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.player-profile-add-image-button {
  height: 30px;
  width: 30px;
  background-color: var(--primaryColor);
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .add-image-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.edit-staff-details-profile {
  height: 30px;
  width: 30px;
  background-color: var(--primaryColor);
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .edit-details-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.delete-staff-details-profile {
  height: 30px;
  width: 30px;
  background-color: #666666;
  position: absolute;
  right: 40px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .edit-details-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.move-player-profile {
  height: 30px;
  width: 30px;
  background-color: #666666;
  position: absolute;
  right: 75px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .edit-details-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.edit-player-details-container {
  // width: 30vw;
  .edit-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .edit-details-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .edit-details-row {
    width: 100%;
    margin-bottom: 15px;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;

    .edit-player-details-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .edit-player-details-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;
      width: 48%;

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      input {
        display: flex;
        height: 50px;
        align-items: center;
        padding-left: 20px;
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }
    }

    .edit-player-details-date {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 20px;
        height: 100%;
      }
      ::before {
        border-bottom: 0 !important;
      }

      ::after {
        border-bottom: 0;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .edit-details-button {
      width: 48%;
      height: 50px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      color: white !important;
      font-weight: 700;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;

      .edit-details-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }
}

.delete-staff-popup-container {
  width: 25vw;
  .delete-staff-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .delete-staff-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .delete-staff-text {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    color: #666666;
  }

  .delete-staff-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .delete-staff-button {
      width: 48%;
      height: 50px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      color: white !important;
      font-weight: 700;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;

      .delete-player-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }

  .change-team-dropdown {
    background-color: #fafafa !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;
    margin-bottom: 40px;

    div {
      color: #666666 !important;
      font-size: 14px !important;
    }

    i {
      margin: 0 !important;
    }

    div.visible.menu.transition > div {
      background-color: #fafafa !important;
      font-weight: 600;
    }
  }
}

.player-transactions {
  padding: 30px 50px;
  height: 100%;
  .player-transactions-container {
    .player-transactions-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      margin-bottom: 40px;

      .player-transactions-title {
        font-size: 20px;
        font-weight: 700;
        color: black;
      }
    }

    .transactions-list-header {
      line-height: 31.75px;
      font-weight: 700;
      font-size: 13px;
      color: #b2b2b2;
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      padding-bottom: 15px;
    }
    .transactions-list {
      max-height: 350px;
      overflow: auto;
      .transaction-row {
        display: flex;
        min-height: 50px;
        align-items: center;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        color: #666666;
      }

      .transaction-description {
        width: 50%;
      }

      .transaction-date {
        width: 25%;
        text-align: center;
      }

      .transaction-amount {
        width: 25%;
        text-align: center;
      }
    }
  }

  .transactions-total-row {
    background-color: #666666;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    height: 55px;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    margin-top: 25px;

    .total-text {
      color: #fff;
      width: 75%;
    }

    .total-amount {
      color: var(--primaryColor);
      width: 25%;
      text-align: center;
    }
  }
}

.custom-parameters-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  position: relative;

  .custom-parameters-title-col {
    width: 33%;
    .custom-parameters-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: 700;
    }
  }

  .custom-parameter-container {
    width: 33%;

    .custom-parameter-header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .param-name {
        font-size: 14px;
        font-weight: 700;
        color: #666666;
        margin-right: 20px;
      }
      .param-value {
        font-size: 30px;
        font-weight: 700;
        color: var(--primaryColor);
        line-height: 31.75px;
        margin-right: 20px;
      }
      .info-circle {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: #f5f5f5;
        color: #666666;
        font-size: 13px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .custom-parameter-explanation {
      color: #b2b2b2;
      font-size: 13px;
      line-height: 20px;
      padding-right: 100px;
      font-weight: 500;
    }
  }

  .custom-parameters-image {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.dropdown-season-select {
  // justify-content: space-between;
  padding-right: 12px;
  margin-bottom: 20px;
  padding-left: 12.5px;
  .loaned-text {
    font-family: "Poppins", sans-serif !important;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    .first-part {
      color: var(--secondaryColor);
    }
    .loaned-team {
      color: var(--primaryColor);
      font-style: italic;
    }
  }
}

.profile-season-dropdown {
  background-color: #666666 !important;
  border: 0 !important;
  display: flex !important;
  align-items: center;
  padding-left: 20px !important;
  font-weight: 700;
  height: 50px;

  div {
    color: white !important;
    font-size: 14px !important;
  }

  i {
    margin: 0 !important;
  }

  div.visible.menu.transition > div {
    background-color: #666666 !important;
    font-weight: 600;
  }
}
