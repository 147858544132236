.add-match-container {
  .add-match-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .add-match-title {
      font-size: 20px;
      font-weight: 700;
    }

    .close-icon-popup {
      cursor: pointer;
      font-size: 20px !important;
    }
  }

  .add-match-row-dialog {
    margin-bottom: 20px;
  }

  .result-value-input {
    width: 48%;
    min-width: 50px;
    max-width: 50px;
  }

  .add-new-location {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #b2b2b2;
  }
  .add-match-row {
    margin-bottom: 15px;
    position: relative;

    .date-input {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }

      .calendar-icon {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin-top: auto !important;
        margin-bottom: auto !important;
      }
    }

    .round-input {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #b2b2b2;
    }

    .mintes-per-time-input,
    .match-format-input {
      width: 100%;
      height: 50px;
    }

    .add-team-dropdown {
      width: 100%;
      select {
        width: 100%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: #fafafa;
        color: #b2b2b2;

        option {
          padding-left: 25px;
          background-color: #fafafa;
          color: #666666;
          font-size: 13px;
        }
      }
    }
  }

  .add-match-buttons {
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 25px;

    .add-match-button {
      background-color: var(--primaryColor);
      height: 50px;
      width: 47%;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }

    .cancel-button {
      background-color: #666666 !important;
    }

    .white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }
}

.matches-header {
  .matches-section-title {
    .add-match-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 12px !important;
      color: white !important;
      display: flex;
      align-items: center;
    }
    .white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }
}

.add-match-row {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  .match-editable-score {
    display: flex;
    gap: 10px;
    align-items: center;
    .score-input {
      width: 50px;
    }
  }
}

.match-result-type-label {
  font-size: 14px;
  color: #666666;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}
