@media(max-width: 1300px){
  .account-transactions-container{
    .account-transactions-header{
      flex-direction: column;
      align-items: flex-start !important;
        div:nth-child(2){
          margin-top: 20px !important;
            .transactions-type-dropdown{
              margin-left: 0px !important;
            }
        }
    }
  }
}


.account-transactions-container {
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  .account-transactions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .account-transactions-title {
      font-size: 20px;
      font-weight: 700;
    }
    .account-balance {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;

      .account-balance-value {
        color: var(--primaryColor);
        padding-left: 5px;
      }
    }
  }

  .account-transactions-table {
    .account-transactions-table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }

    .description-column {
      width: 25%;

      .transaction-description {
        font-size: 11px;
        color: #b2b2b2;
      }
    }

    .category-column {
      width: 25%;
      text-align: center;
    }

    .date-column {
      width: 25%;
      text-align: center;
    }

    .amount-column {
      width: 25%;
      text-align: center;

      .income {
        color: #00d948;
      }
      .outcome {
        color: red;
      }
    }

    .account-transaction-row {
      color: #666;
      height: 70px;
      display: flex;
      align-items: center;
      font-weight: 700;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.account-transactions-chart-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 31.75px;
  padding-bottom: 30px;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  position: relative;

  .dropdown-ppp {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;

    .dropdown-label {
      margin-right: 20px;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .total {
    position: absolute;
    right: 0;
    width: 25%;
    text-align: center;

    .total-row {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.filter-interval-date {
  font-family: Poppins, sans-serif !important;
  font-size: 12px !important;
  color: #fff !important;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  margin-left: 20px;

  .filter-interval-icon {
    color: white;
    opacity: 1 !important;
  }

  .interval {
    color: #b2b2b2;
    font-size: 11px !important;
  }
}

.transactions-type-dropdown {
  background-color: #666666 !important;
  display: flex !important;
  align-items: center;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  height: 50px;
  min-width: 200px !important;
  margin-right: 20px;

  .divider.default.text {
    color: white !important;
  }

  i {
    top: 36% !important;
    color: white;
  }

  .divider.text {
    color: white !important;
  }
}

.date-picker-transactions-container {
  display: flex;
  justify-content: center;
}

.date-filter-buttons-row {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 22vw;
}

.date-picker-transactions-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
}
