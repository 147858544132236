.tfbDialog {
  .tfbDialogTitle {
    display: flex;
    justify-content: space-between;

    .header {
      font-size: 20px;
      font-weight: 700;
    }
    .dialogCloseIcon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .tfbDialogContent {
  }

  .tfbDialogActions {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;

    .btnTFBTwo {
      margin: 0;
      width: 48%;
    }

    .btnTFBThree {
      margin: 0;
      width: 31%;
    }

    div {
      button {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }

  .tfbDialogStickyActions {
    position: sticky;
    bottom: 0;
    z-index: 999;
  }
}

@media only screen and (max-width: 570px) {
  .tfbDialog {
    .tfbActionsColumn {
      flex-direction: column;
      gap: 15px;

      .btnTFBTwo {
        margin: 0;
        width: 100%;
      }
    }
  }
}
