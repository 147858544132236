@media (max-width: 600px) {
  .player-profile-certificates-container {
    // margin: 5%;
    padding: 0px 0px 0px !important;

    .profile-certificates-title-container {
      font-weight: 700;
      color: #000;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding-left: 50px;
    }
    .table-certificates-container {
      .certificate-header-row {
        display: flex;
        color: #b3b3b3;
        font-weight: 600;
        font-size: 13px;
        padding-left: 15px;
        padding-bottom: 15px;
        font-family: "Poppins", sans-serif !important;

        .col-header {
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .date-examination {
          justify-content: end !important;
          width: 25%;
        }
        .date-expire {
          width: 25%;
        }
        .period-expire {
          width: 25%;
        }
        .img-certificate {
          width: 25%;
        }
      }

      .certificate-row {
        display: flex;
        height: 60px;
        align-items: center;
        font-weight: 700;
        color: #666666;
        padding-left: 15px;

        .row1 {
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .date-examination {
          justify-content: start;
          width: 25%;
        }
        .date-expire {
          width: 25%;
        }
        .period-expire {
          width: 25%;
        }
        .img-certificate {
          width: 25%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .button-content {
    line-height: 16px;
  }

  .add-treatment-button-container {
    .add-treatment-button {
      width: 100%;
    }
  }

  .injury-big-popup-title {
    width: 100% !important;
  }
}

.detailed-injury-popup-dates {
  display: flex;
  justify-content: space-between;
  width: 25vw;
}

.injury-popup-item-subtitle {
  margin-right: 20px;
  color: #666666;
  font-weight: 800;
  margin-top: 15px;
  align-self: flex-end;
}

.injury-popup-item-title {
  color: #b2b2b2;
  margin-right: 10px;
  font-weight: 800;
}

.injury-popup-item {
  display: flex;
  color: #666666;
  font-weight: 800;
}

.injuries-widget-title {
  padding-left: 15px;
  padding-top: 15px;
  font-weight: 800;
  display: flex;
  width: 100%;
  padding-right: 10px;
  justify-content: space-between;
}

.treatment-container {
  overflow-y: auto;
  max-height: 300px;
}

.treatment-row {
  display: flex;
  width: 20vw;
  justify-content: space-between;
  height: 2vw;
  align-items: center;

  .treatment-row-title {
    font-weight: 600;
    color: #b2b2b2;
  }

  .treatment-row-value {
    text-align: center;
    display: flex;
    justify-content: space-between;
    color: #666666;
    font-weight: 600;
  }
}

.treatment-name-total {
  font-weight: 600;
  width: 35%;
}

.treatment-cost-total {
  width: 65%;
  text-align: right;
  font-weight: 600;
}

.injury-big-popup-title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 40px;
  width: 25vw;
}
.injury-big-content-container {
  width: 575px;
}

.injury-big-popup-subtitle {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 20px;
}

.injury-big-popup-column-titles {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding-bottom: 15px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  color: #b2b2b2;
  padding-left: 7px;
  padding-right: 7px;
}

.injury-big-popup-values {
  display: flex;
  justify-content: space-between;
  align-self: center;
  height: 55px;
  background-color: #fafafa;
  align-items: center;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  color: #666666;
  padding-left: 7px;
  padding-right: 7px;
}

.injury-column-title {
  width: 33%;
  text-align: center;
}

.injury-big-popup-total-container {
  background-color: #666666;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  height: 55px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 30px;
}

.injury-big-popup-total-text {
  color: #fff;
  margin-left: 10px;
}

.injury-big-popup-total-amount {
  color: var(--primaryColor);
  margin-right: 10px;
}

.no-injuries-placeholder {
  text-align: center;
  margin-top: 30px;
}

.no-injuries-placeholder-text {
  font-size: 15px;
  font-weight: 600;
  padding-top: 5px;
}

.unexpanded-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 700;
  height: 55px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Poppins", sans-serif !important;

  .unexpanded-row-title {
    color: #666666;
  }

  .unexpanded-row-right {
    display: flex;

    .unexpanded-row-cost {
      color: var(--primaryColor);
      padding-right: 10px;
    }
  }
}

.expanded-row {
  width: 100%;
  font-weight: 500;

  .expanded-row-date {
    color: #666666;
    font-size: 13px;
    padding-left: 20px;
  }
}

.expanded-row-description {
  width: 20vw;
  font-size: 13px;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 10px;
  color: #bdbdbd;
  font-weight: 500;
}

.green-eur-icon {
  color: #00d948 !important;
}

.add-new-treatment-button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
}

.injury-edit-delete-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-weight: 600;
  height: 50px;
}

.custom-button-dual {
  width: 47%;
  height: 50px;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
}

.edit-button {
  background-color: #666666 !important;
  color: white !important;
}

.delete-button {
  background-color: #d4d4d4 !important;
  color: #666666 !important;
}

.add-treatment-button {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.button-content {
  display: flex;
  justify-content: space-between;

  .edit-icon {
    color: white;
  }

  .delete-icon {
    color: #666666;
  }

  .add-icon {
    color: white;
  }
}

.treatment-cancel-button {
  background-color: #666666 !important;
  color: white !important;
}

.treatment-add-button {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.treatment-options {
  padding-left: 10px;
  padding-right: 10px;
}

.add-injury-form-container {
  // width: 25vw;
  width: 100%;

  .add-injury-row {
    height: 50px;
    margin-bottom: 15px;
    background-color: #fafafa;
    position: relative;

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .add-injury-form-dropdown {
      background-color: #fafafa;
      height: 100%;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #b2b2b2;
      padding-left: 20px;
      border-radius: 3px;
      width: 100%;
    }

    .add-injury-custom-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;

      .menu {
        max-height: 150px !important;
      }

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      i {
        margin: 0 !important;
      }
    }

    .injury-date-input {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .text-area-edit-injury {
      width: 100%;
      font-size: 13px;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding: 10px;
      padding-left: 20px;
      border: 0;
      background-color: #fafafa;
    }
  }
}

.error-container {
  text-align: center;
  padding-bottom: 15px;
  color: red;
}

//PLAYER PROFILE INJURIES SECTION

.player-profile-injury-container {
  height: 400px;
  display: flex;
  padding: 0 50px;

  img {
    height: 100%;
  }

  .injuries-table-container {
    width: 100%;
  }
}

.injuries-table {
  // margin-left: 20px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  padding-right: 50px;
  padding: 20px 50px 30px;
  padding-bottom: 0px;

  .injury-row {
    color: #666666;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
  }

  .injury-name {
    width: 30%;
    padding-left: 10px;
  }

  .injury-date {
    width: 50%;
    text-align: center;
  }

  .injury-details {
    width: 20%;
    text-align: center;
    .eye-icon-container {
      height: 13px;
      cursor: pointer;
    }
  }
}

.add-injury-row-end-injury {
  height: 50px;
  margin-bottom: 15px;
  background-color: #fafafa;
  position: relative;

  .calendar-icon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

//Injury details popup

.add-treatment-button-container {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  place-content: flex-end;
}

.player-profile-certificates-container {
  // margin: 5%;
  padding: 20px 50px 30px;

  .profile-certificates-title-container {
    font-weight: 700;
    color: #000;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-left: 50px;
  }
  .table-certificates-container {
    .certificate-header-row {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-bottom: 15px;
      font-family: "Poppins", sans-serif !important;

      .col-header {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .date-examination {
        justify-content: start;
        width: 25%;
      }
      .date-expire {
        width: 25%;
      }
      .period-expire {
        width: 25%;
      }
      .img-certificate {
        width: 25%;
      }
    }

    .certificate-row {
      display: flex;
      height: 60px;
      align-items: center;
      font-weight: 700;
      color: #666666;
      padding-left: 15px;

      .row1 {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .date-examination {
        justify-content: start;
        width: 25%;
      }
      .date-expire {
        width: 25%;
      }
      .period-expire {
        width: 25%;
      }
      .img-certificate {
        width: 25%;
      }
    }
  }
}
