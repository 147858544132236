.precense-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .all-time-presence-list {
    .precense-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: max-content;

      .player-name {
        font-weight: 900;
        width: 20%;
        min-width: 120px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .player-presence-list-progress-bar {
    width: 70%;
    position: relative;
    .no-presence {
      position: absolute;
      left: 0;
      right: 0;
      top: 8px;
      margin-left: auto;
      margin-right: auto;
      width: 100px; /* Need a specific value to work */
      display: flex;
      justify-content: center;
      font-weight: 700;
    }

    .progress {
      height: 30px !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  .player-presence-list-progress-bar {
    width: 80%;
    position: relative;
    .no-presence {
      position: absolute;
      left: 0;
      right: 0;
      top: 8px;
      margin-left: auto;
      margin-right: auto;
      width: 100px; /* Need a specific value to work */
      display: flex;
      justify-content: center;
      font-weight: 700;
    }

    .progress {
      height: 35px !important;
    }
  }
}
