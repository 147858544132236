.options-operation-container {
  display: flex;
  width: 475px;
  .option-operation {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .op-button-name {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      cursor: pointer;
    }
  }
}

.form-elements-container {
  .operation-form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;

    .invoice-operation-input {
      width: 100%;
      height: 50px;
      background-color: white;
    }

    .input-container {
      width: 45%;
      padding-top: 30px;
      padding-bottom: 20px;
      position: relative;
    }

    .out-of {
      font-weight: 700;
    }
  }

  .operation-textfield {
    background-color: white !important;
  }
}

.buttons-container-operations {
  margin-top: 25px;
  padding-bottom: 20px;
  margin-bottom: 40px;

  .operation-cancel-button {
    background-color: #666666 !important;
    color: white !important;
    width: 47%;
    height: 50px;

    .op-cancel-icon {
      color: white !important;
    }
  }

  .operation-save-button {
    background-color: #00d948 !important;
    color: white !important;
    width: 47%;
    height: 50px;
  }
}

#kt_body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  > div
  > div.MuiDialogContent-root
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > div:nth-child(2)
  > div
  > div {
  height: 100%;
}

.payment-operation-input {
  height: 50px;
  width: 100%;
}

.integral-amount-button {
  height: 35px;
  width: 70px;
  position: absolute;
  font-size: 11px !important;
  right: 5px;
  top: 37px;
}
