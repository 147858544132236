.row-setup {
    justify-content: center;
    margin-bottom: 40px;
    .col-lg-6 {
        .card-custom {
            .elem-init {
                padding: 40px;
                display: flex;
                cursor: pointer;
                .elem-icon {
                    width: 10%;
                    display: flex;
                    align-items: center;
                    .icon {
                        color: var(--primaryColor);
                    }
                }
                .elem-title {
                    width: 80%;
                    font-size: 16px;
                    line-height: 32px;
                    font-weight: 700;
                    font-family: "Poppins";
                }
                .plus {
                    justify-content: flex-end;
                }
            }
        }
    }
}
