@media only screen and (max-width: 1160px) {
  .plan-training-card-header {
    flex-direction: column !important;
  }
}

.training-history-actions-wrapper {
  gap: 5px;
  display: flex;
  justify-content: flex-end;
  .rpe-icon-container {
    width: 40px !important;
    height: 35px !important;
    background-color: #666666;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;

    .rpe-icon-text {
      color: #fafafa;
    }
  }
}

.training-history-container {
  .rpe-icon-container {
    width: 40px !important;
    height: 35px !important;
    background-color: #666666;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;

    .rpe-icon-text {
      color: #fafafa;
    }
  }
  .eye-icon-container {
    background-color: #666666;
    width: 35px;
    height: 35px;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
  .no-training-call-up-message {
    text-align: center;
    .no-training-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.training-table-history-list .training-row-table-header {
  display: flex;
}

.training-table-history-container .training-row-table-header {
  display: none !important;
}

.training-history-container {
  padding: 50px;

  .training-history-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .training-history-title {
      font-size: 20px;
      font-weight: 700;
    }

    .training-teams-dropdown {
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      min-width: 200px !important;

      .divider.default.text {
        color: white;
      }

      i {
        top: 36% !important;
        color: white;
      }

      .divider.text {
        color: white;
      }
    }
  }

  .training-row-table-header {
    display: flex;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
  }

  .training-history-list {
    .training-row {
      min-height: 55px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      padding-left: 25px;
      padding-right: 25px;
    }

    .team-column {
      width: 17%;
    }

    .confirmed-presence {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      gap: 4px;

      .presence {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: #fafafa !important;
        width: 33px;
        height: 33px;
      }
    }

    .date-column-start,
    .date-column-end {
      width: 10%;
      text-align: center;
    }

    .date-column {
      width: 15%;
      text-align: center;
    }

    .options-column {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;

      .trash-hover:hover {
        background-color: #d91200;
        cursor: pointer;
      }

      .edit-icon-container-plan-training {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;
        i {
          color: white;
          position: relative;
          left: 3px;
        }
      }

      .edit-trash-container-plan-training {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;
        i {
          color: white;
          position: relative;
          left: 2px;
        }
      }

      .eye-icon-container {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .training-row-table-header.mobile-header {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 992px) {
  .training-history-container {
    .desktop-header {
      display: flex !important;
    }

    .header-row {
      display: none !important;
    }

    .content-row {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .training-history-list {
    .training-row {
      .content-row {
        .options-column {
          .eye-icon-container {
            width: 35px !important;
            height: 35px !important;
          }

          .rpe-icon-container {
            width: 40px !important;
            height: 35px !important;
            background-color: #666666;
            padding: 7px;
            border-radius: 5px;
            cursor: pointer;

            .rpe-icon-text {
              color: #fafafa;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .training-history-container {
    .desktop-header {
      display: none !important;
    }

    .training-row {
      // height: 230px !important;
      padding: 20px !important;
    }

    .training-row-table-header.header-row {
      padding: 0 !important;
    }

    .header-row {
      display: block;
      width: 50%;
      height: 100%;
    }

    .mobile-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div {
        width: 100% !important;
        height: 37px !important;
        display: flex;
        justify-content: flex-start;
      }
    }

    .training-row {
      .content-row {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        div:not(div:last-child) {
          width: 100% !important;
          height: 45px !important;
          padding-bottom: 15px;
          display: flex;
          justify-content: flex-start;
        }

        .options-column {
          width: 100% !important;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .training-history-list {
      .training-row {
        .content-row {
          .options-column {
            .eye-icon-container {
              width: 35px !important;
              height: 35px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .training-history-container {
    .training-history-header {
      flex-direction: column !important;
      align-items: flex-start !important;

      .training-teams-dropdown {
        width: 100%;
      }
    }

    .training-history-title {
      margin-bottom: 10px;

      + div {
        .training-teams-dropdown {
          font-size: 11px !important;
          min-width: 150px !important;
        }

        button div {
          font-size: 11px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }

  .training-history-container {
    padding: 20px !important;
  }
}
