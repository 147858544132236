@media (min-width: 991px) {
  .bonuses-table-header-mobile {
    display: none !important;
  }

  .no-payments-made {
    text-align: center;
  }

  .bonuses-monitoring-container {
    padding: 40px;
    .player-data {
      display: flex;
      align-items: center;
      .player-img-container {
        // background-color: #b2b2b2;
        border-color: #b2b2b2;
        border-width: 1px;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        margin-right: 15px;
        .player-img {
          height: 50px;
          width: auto;
          border-radius: 25px;
        }
      }
      .player-name {
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        font-family: "Poppins";
      }
    }

    .bonuses-table {
      margin-top: 40px;
      .bonuses-table-header {
        display: flex;
        padding-bottom: 20px;
        .col-b {
          text-align: start;
          font-size: 13px;
          color: #b3b3b3;
          font-weight: 700;
          font-family: "Poppins";
        }
        .bonus-name {
          width: 15%;
          padding-left: 20px;
        }
        .bonus-progress {
          text-align: center;
          width: 13%;
        }
        .match-date-realization {
          width: 27%;
          text-align: center;
        }
        .bonus-value {
          width: 15%;
          text-align: center;
        }
        .done-payments {
          width: 15%;
          text-align: center;
        }
        .total-made-payments {
          width: 8%;
        }
        .remaining-to-pay {
          width: 8%;
        }
      }
      .bonuses-table-list {
        .players-bonuses {
          .bonus-row {
            display: flex;
            padding: 20px 0;
            .wrapper-bonus-name {
              width: 15%;
            }

            .wrapper-bonus-progress {
              width: 13%;
            }

            .wrapper-match-date-realization {
              width: 27%;
            }

            .wrapper-bonus-value {
              width: 15%;
            }

            .wrapper-done-payments {
              width: 15%;
            }
            .wrapper-total-made-payments {
              width: 8%;
            }
            .wrapper-remaining-to-pay {
              width: 8%;
            }

            .bonuses-content {
              .col-b {
                font-size: 14px;
                color: #666666;
                font-weight: 700;
                font-family: "Poppins";
              }
              .bonus-name {
                // width: 100%;
                padding-left: 20px;
              }
              .bonus-progress {
                // width: 13%;
                text-align: center;
              }
              .match-date-realization {
                // width: 27%;
                text-align: center;

                .match-data {
                  // display: flex;
                  justify-content: center;
                  margin-bottom: 10px;
                  .match-quantity {
                    display: flex;
                  }
                  .match-vs {
                    display: flex;
                    margin: 0 5px;
                    color: #b2b2b2;
                  }

                  .match-opponent-img {
                    height: 20px;
                    width: auto;
                    margin: 0 5px;
                  }

                  .match-date {
                    color: #b2b2b2;
                    margin-left: 5px;
                  }

                  .bonus-grey-text {
                    color: #b2b2b2;
                  }
                }
              }
              .bonus-value {
                // width: 15%;
                text-align: center;
              }
              .done-payments {
                // width: 15%;
                .bonus-payment-row {
                  display: flex;

                  .payment-date {
                    color: #b2b2b2;
                  }
                }
              }
              .total-made-payments {
                // width: 8%;
              }
              .remaining-to-pay {
                // width: 8%;
              }
            }
          }
        }
      }
    }
  }
  .season-select-bonuses-page {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-bottom: 10px;
    // top: 26px;
    // right: 25px;
    .players-season-dropdown {
      width: 100px;
    }
  }

  .display-none-desktop {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .wrapper-match-date-realization {
    margin-bottom: 5px;
  }

  .bonus-header-color {
    color: #b3b3b3 !important;
  }

  .card-custom {
    margin-bottom: 0px !important;
  }

  .players-bonuses {
    display: flex;
    // justify-content: space-between;
  }

  .bonuses-content {
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
  }

  .bonuses-table-desktop {
    display: none !important;
  }

  .content {
    // padding: 0 5px !important;
  }
  //   .container-fluid {
  //     padding: 0 5px;
  //   }

  .bonuses-monitoring-container {
    .player-data {
      padding-top: 15px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      .player-img-container {
        // background-color: #b2b2b2;
        border-color: #b2b2b2;
        border-width: 1px;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        margin-right: 15px;
        .player-img {
          height: 50px;
          width: auto;
          border-radius: 25px;
        }
      }
      .player-name {
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        font-family: "Poppins";
      }
    }

    .bonuses-table {
      display: flex;
      margin-top: 20px;
      .bonuses-table-header {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .col-b {
          text-align: start;
          font-size: 13px;
          color: #b3b3b3;
          font-weight: 700;
          font-family: "Poppins";
        }
      }

      .bonuses-table-list {
        width: 100%;
        .bonus-row {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          .col-b {
            font-size: 14px;
            color: #666666;
            font-weight: 700;
            font-family: "Poppins";
          }
          .bonus-name {
            width: 50%;
            font-size: 1em;
          }
          .bonus-progress {
            font-size: 1em;
            width: 50%;

            // text-align: center;
            // margin-left: 5px;
            // margin-right: 5px;
          }
          .match-date-realization {
            width: 50%;

            font-size: 1em;
            // white-space: nowrap;

            .match-data {
              // display: flex;
              justify-content: center;
              margin-bottom: 10px;
              .match-quantity {
                display: flex;
              }
              .match-vs {
                display: flex;
                margin: 0 5px;
                color: #b2b2b2;
              }

              .match-opponent-img {
                height: 20px;
                width: auto;
                margin: 0 5px;
              }

              .match-date {
                color: #b2b2b2;
                margin-left: 5px;
              }

              .bonus-grey-text {
                color: #b2b2b2;
              }
            }
          }
          .bonus-value {
            width: 50%;

            font-size: 1em;
            // text-align: center;
          }
          .done-payments {
            width: 50%;

            font-size: 1em;
            // margin: 0px 5px 0px 5px;

            .bonus-payment-row {
              display: flex;

              .payment-date {
                color: #b2b2b2;
              }
            }
          }
          .total-made-payments {
            width: 50%;

            font-size: 1em;
          }
          .remaining-to-pay {
            width: 50%;

            font-size: 1em;
          }
        }
      }
    }
  }
  .season-select-bonuses-page {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    // top: 26px;
    // right: 25px;
    .players-season-dropdown {
      margin-left: 0px;
      width: 100%;
    }
  }

  // .no-payments-made {
  //   text-align: center;
  // }
}
