.add-comp-container {
  width: 350px;
  // min-height: 350px;

  .add-comp-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .add-comp-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }

    .close {
      cursor: pointer;
    }
  }

  .comp-minutes-per-time-input {
    width: 100%;
    input {
      background-color: #fafafa !important;
      padding-left: 24px !important;
      border: 0 !important;
      font-family: "Poppins" !important;
      font-weight: 900 !important;
    }

    ::placeholder {
      color: #b2b2b2 !important;
    }
  }

  .add-comp-row {
    margin-bottom: 15px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .add-comp-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
    }

    .add-checkbox-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      padding-top: 15px;

      label {
        color: #b3b3b3;
      }

      label:hover {
        // color: #b3b3b3;
      }
    }

    .comp-date-input {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .add-comp-dropdown {
    background-color: #fafafa !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    width: 100% !important;
    color: #666666 !important;
    border: 0 !important;
    padding-left: 25px !important;

    .divider.default.text {
      color: #b2b2b2 !important;
    }

    i {
      color: #666666 !important;
    }

    .divider.text {
      color: #666666 !important;
    }
  }
}

.add-comp-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 25px;
  margin-top: 10px;

  .add-comp-button {
    background-color: var(--primaryColor) !important;
    height: 50px;
    width: 47%;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .cancel-button {
    background-color: #666666 !important;
    height: 50px;
    width: 47%;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .white-icon {
    color: white;
    padding-left: 20px;
    opacity: 1 !important;
  }
}

@media (max-width: 991px) {
  .add-comp-container {
    width: 100%;
  }
}
