// .close-video + .MuiDialogContent-root {
//     overflow-y: hidden;
// }

.video-player {
    // width: 100%;
    // height: 10000px;
    overflow-y: none !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .event-title {
        // padding-top: 25px;
        // padding-bottom: 25px;
        font-size: 20px;
        font-weight: 700;
        color: #000000;
    }

    .event-text {
        font-size: 16px;
        font-weight: 700;
        color: #666666;
    }

    .informations {
        width: 100%;
        padding-right: 50px;
        height: 400px;
        overflow-y: auto;
    }

    .add-event-container {
        // width: 100%;
        // height: auto;
        // padding: 50px 0;
        .button {
            padding: 0.78571429em 1.5em 0.78571429em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primaryColor);
            color: white;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-weight: 700;
            line-height: 1em;
            border: none;
            border-radius: 0.28571429rem;
            cursor: pointer;
        }
    }

    .generated-row {
        // display: flex;
        // justify-content: space-between;

        .button-container {
            // padding-left: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        // .video-event-row {
        //     padding-bottom: 0;
        // }
    }

    .plus-button {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .additional-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 1em;
        border: none;
        border-radius: 0.28571429rem;
        cursor: pointer;

        .additional-icon {
            color: var(--primaryColor);
        }
    }

    .tags-video-container {
        border-width: thick;
        border: rgb(229 229 229) solid;
        padding: 15px;
        margin-bottom: 25px;
    }

    .video-event-container {
        // width: 100%;
        // height: auto;
        // display: flex;
        // align-items: flex-start;
        // justify-content: flex-start;
        // flex-direction: column;

        div {
            padding-bottom: 25px;
        }

        .video-event-row {
            display: flex;
            justify-content: space-between;

            .time-container:first-of-type {
                // margin-right: 25px;
            }

            .description:first-of-type {
                // margin-right: 25px;
            }

            select,
            input.event-type {
                // width: 260px;
                height: 44px !important;
            }

            input.event-type {
                // margin-right: 25px;
            }
        }

        .button-row {
            justify-content: normal;
            padding-bottom: 0;
        }

        .video-title-description {
            width: 100%;
            display: flex;
            padding-bottom: 25px;
            flex-direction: column !important;

            input {
                min-height: 44px;
                margin-bottom: 25px;
            }

            .description {
                width: 100%;
            }
        }

        .generate-button {
            padding-bottom: 0px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
        }
        .button {
            padding: 0.78571429em 1.5em 0.78571429em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primaryColor);
            color: white;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-weight: 700;
            line-height: 1em;
            border: none;
            border-radius: 0.28571429rem;
            cursor: pointer;
        }
    }

    .time-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        .button {
            // width: 135px;
            // margin-right: 25px;
        }
    }

    .time {
        width: 100px;
        // height: 100%;
        padding: 0.67857143em 1em;
        padding-left: 20px;
        background-color: #fafafa;
        color: #666666;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.21428571em;
        border: 0;
    }

    .event-type {
        height: 40px !important;
        background-color: #fafafa !important;
        color: #666666 !important;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.21428571em;
    }

    .description {
        width: 260px;
        height: auto;
        min-height: 80px;
        padding: 0.67857143em 1em;
        padding-left: 20px;
        background-color: #fafafa;
        color: #666666;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.21428571em;
        border: 0;
    }

    .description::placeholder {
        color: #b2b2b2;
    }
}

.line-beetwen-tags {
    margin-bottom: black 3px solid;
}

.video-chapters {
    width: 300px;
    border-width: thick;
    border: #e5e5e5 solid;
    margin-bottom: 30px;
    .chapters-title {
        padding: 15px 0;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color: #000000;
    }
    .chapters-container {
        height: 700px;
        overflow-y: scroll;

        .chapter-item {
            padding: 10px 10px 10px 0;
            display: flex;
            cursor: pointer;
            .active-chapter-container {
                padding: 0 4px;
                display: flex;
                align-items: center;
            }
            .chapter-data {
                .chapter-title {
                    color: #666666;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                }
                .chapter-timeline {
                    background-color: rgb(0, 217, 72, 0.3);
                    padding: 3px;
                    margin-top: 3px;
                    width: fit-content;
                }
            }
        }
        .chapter-item:hover {
            background-color: #e5e5e5;
        }
    }
}
