.tfbDateTimePicker {
  background-color: #fafafa;

  div {
    color: #b2b2b2;
    font-size: 14px;
    font-weight: 700;
    padding-left: 5px;
  }

  fieldset {
    border-color: #fafafa;
  }
}

.tfbDateGroup {
}
.dateLabel {
  color: #b2b2b2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}
