.competitions-manual-container {
  padding: 50px;

  .competitions-manual-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .competitions-manual-title {
      font-size: 20px;
      font-weight: 700;
    }

    .competitions-manual-add-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex;
      align-items: center;

      .add-button-white-icon {
        color: white;
        padding-left: 20px;
        opacity: 1 !important;
      }
    }
  }
}

.delete-competition-wrapper {
  width: 100%;
}

.add-team-manual-image {
  .image-input-container {
    background-color: #fafafa;
    padding-right: 0px;
    width: 100%;
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-weight: 700;
      color: #666666;
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      overflow: visible;
      width: 2px;
    }

    label {
      font-weight: 700;
      font-size: 14px;
      color: #b2b2b2;
      padding-left: 0px;
      cursor: pointer;
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto !important;
      .image-view {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 0.5px solid #d2d2d2;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 80%;
          height: auto;
          max-height: 80%;
          width: auto;
        }
        span {
          font-size: 12px;
          display: flex;
          width: 100%;
          align-items: center;
          height: 100%;
          text-align: center;
          justify-content: center;
          i {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

.additional-button {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  border: none;
  border-radius: 0.28571429rem;
  cursor: pointer;
  // margin-left: 15px;

  .additional-icon {
    color: var(--primaryColor) !important;
  }
}

.more-button {
  padding-left: 0px !important;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  border: none;
  border-radius: 0.28571429rem;
  cursor: pointer;

}

@media only screen and (min-width: 1052px) {
  // .btn-secondary {
  //   // margin-right: 10px !important;
  //   i {
  //     position: relative;
  //     left: 4px;
  //   }
  // }

  .extended-competition-mobile {
    display: none;
  }

  .competition-options {
    width: 15%;
    text-align: end;
    gap: 10px;
    .winner-position {
      i {
        position: relative;
        right: 10px;
      }
    }

    .btn.btn.btn-secondary {
      width: 54px;
    }

    img {
      width: 12px;
      cursor: pointer;
    }

    .additional-button.btn.btn-secondary {
      i {
        position: relative;
        left: 5px;
      }
    }

    .competition-more {
      .btn-secondary {
        // margin-right: 10px !important;
        i {
          position: relative;
          left: 8px;
        }
      }

      .caret-up-position {
        i {
          position: relative;
          left: 7px;
        }
      }
    }
  }

  .mobile-head-competition {
    display: none;
  }

  .competitions-manual-list {
    .competitions-manual-row-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      // padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;

      .competition-name {
        width: 20%;
      }

      .competition-number-teams {
        width: 20%;
      }
    }

    .competition-name-wrapper {
      width: 20%;
      .competition-name {
        width: 100%;
      }
    }

    .competitions-manual-row-header-extended {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      // padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;

      .competition-name-child {
        // width: calc(25% - 30px);
        width: 16%;
      }

      .competition-options {
        width: 16%;
      }

      .competition-name {
        width: 16%;
      }

      .competition-date {
        width: 16%;
      }

      .competition-number-teams-child {
        width: 16%;
      }

      .competition-number-teams {
        width: 16%;
      }

      .competition-name-wrapper {
        width: 16%;
        .competition-name-child {
          width: 100%;
        }
      }

      .competition-number-teams-wrapper {
        width: 16%;
        .competition-number-teams {
          width: 100%;
        }
      }

      .competition-number-teams-child-wrapper {
        width: 16%;
        .competition-date {
          width: 100%;
        }
      }

      .competition-date-start-wrapper {
        width: 16%;
        .competition-date {
          width: 100%;
        }
      }

      .competition-date-end-wrapper {
        width: 16%;
        .competition-date {
          width: 100%;
        }
      }
    }

    .extended-competition {
      .competition-number-teams-wrapper {
        width: 16%;
        .competition-number-teams-child {
          width: 100%;
        }
      }

      .competition-number-teams-child-wrapper {
        width: 16%;
        .competition-date {
          width: 100%;
        }
      }

      .competition-date-start-wrapper {
        width: 16%;
        .competition-date {
          width: 100%;
        }
      }

      .competition-date-end-wrapper {
        width: 16%;
        .competition-date {
          width: 100%;
        }
      }

      .competition-options {
        width: 20%;
      }

      .competition-name-wrapper {
        width: 16%;
      }
    }

    .competition-number-teams-wrapper {
      width: 20%;
      .competition-number-teams {
        width: 100%;
      }
    }

    .competition-number-teams-child-wrapper {
      width: 20%;
      .competition-date {
        width: 100%;
      }
    }

    .competition-date-start-wrapper {
      width: 20%;
      .competition-date {
        width: 100%;
      }
    }

    .competition-date-end-wrapper {
      width: 20%;
      .competition-date {
        width: 100%;
      }
    }

    .competitions-manual-row {
      color: #666;
      height: 70px;
      display: flex;
      align-items: center;
      font-weight: 700;
      // padding-left: 15px;
      padding-right: 15px;
    }

    .competition-number-teams-child {
      width: 15%;
      text-align: center;
    }

    .competition-options {
      display: flex;
      align-items: center;
      width: 20%;
      justify-content: flex-end;

      img {
        width: 12px;
        cursor: pointer;
      }
    }
  }

  .competition-number-teams {
    width: 20%;
    text-align: center;
  }

  .competition-date {
    // width: 15%;
    width: 20%;
    text-align: center;
  }

  .competition-name {
    width: calc(35% - 30px);
  }

  .competitions-manual-container {
    .desktop-header {
      display: flex !important;
      padding-left: 12px;
    }

    .header-row {
      display: none;
    }

    .content-row {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 12px;
    }
  }
}

@media only screen and (max-width: 1051px) {
  .competition-options {
    // width: 15%;
    // text-align: end;
    // gap: 10px;
    .winner-position {
      i {
        position: relative;
        right: 10px;
      }
    }

    .btn.btn.btn-secondary {
      width: 54px;
    }

    img {
      width: 12px;
      cursor: pointer;
    }

    .additional-button.btn.btn-secondary {
      i {
        position: relative;
        left: 5px;
      }
    }

    .competition-more {
      .btn-secondary {
        // margin-right: 10px !important;
        i {
          position: relative;
          left: 8px;
        }
      }

      .caret-up-position {
        i {
          position: relative;
          left: 7px;
        }
      }
    }
  }

  .competition-date-end-wrapper {
    width: 100%;
    padding-bottom: 15px;
    display: flex;
    justify-content: flex-start;
  }

  .extended-competition-desktop {
    display: none;
  }

  .btn-secondary {
    i {
      position: relative;
      left: 4px;
    }
  }

  // .padding-right3px {
  //   padding-right: 3px !important;
  // }

  // .team-see-more-button {
  //   background-color: #666666;
  //   width: 35px;
  //   height: 35px;
  //   padding: 7px;
  //   border-radius: 5px;
  //   cursor: pointer;

  //   .icon-notification {
  //     .notification-container {
  //       position: relative;
  //       top: -35px;
  //       right: -15px;
  //       .notification-circle {
  //         background-color: red;
  //         border-radius: 10px;
  //         height: 20px;
  //         width: 20px;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         .notification-number {
  //           color: white;
  //           font-size: 10px;
  //         }
  //       }
  //     }
  //   }
  // }

  .competitions-manual-list {
    .competitions-manual-row-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      // padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }
    .competitions-manual-row {
      color: #666;
      height: 70px;
      display: flex;
      align-items: center;
      font-weight: 700;
      // padding-left: 15px;
      padding-right: 15px;
    }
    .competition-more {
      padding: 0;
      // width: 30px;
    }

    .competition-name-child {
      // width: calc(25% - 30px);
      width: 100%;
    }

    .competition-number-teams-child {
      width: 100%;
      // text-align: center;
    }

    .competition-options {
      width: 15%;
      text-align: end;
      gap: 25px;

      img {
        width: 12px;
        cursor: pointer;
      }
    }
  }

  .competition-number-teams {
    width: 100%;
    // text-align: center;
  }

  .competition-name {
    width: 100%;
  }

  .competition-date {
    width: 100%;
    // text-align: center;
  }

  .competitions-manual-container {
    .desktop-header {
      display: none !important;
    }

    .competitions-manual-row {
      // height: 210px !important;
      height: max-content;
      padding: 20px !important;
    }

    .competitions-manual-row-header.mobile-header {
      padding: 0 !important;
    }

    .header-row {
      display: block;
      width: 50%;
      height: 100%;
    }

    .mobile-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div {
        width: 100% !important;
        // height: 37px !important;
        display: flex;
        justify-content: flex-start;
      }
    }

    .content-row {
      // width: 50%;
      width: 100%;
      // height: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div:not(div:last-child) {
        width: 100% !important;
        // height: 45px !important;
        padding-bottom: 15px;
        display: flex;
        justify-content: flex-start;
      }

      .competition-options {
        width: 100% !important;
        display: flex;
        position: relative;
        justify-content: flex-end;
        gap: 12px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .competitions-manual-add-button {
    width: 100%;
    background-color: var(--primaryColor) !important;
    height: 50px;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex;
    align-items: center;

    .add-button-white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }

  .competition-name-wrapper {
    .competition-name {
      width: 100%;
    }
  }

  .competitions-manual-header {
    margin-bottom: 10px !important;
    flex-direction: column;
    align-items: flex-start !important;

    .competitions-manual-title {
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .competitions-manual-container {
    padding: 20px;

    .container-fluid {
      padding: 0 !important;
    }
  }
}

.extend:hover {
  color: var(--primaryColor);
}
