.presence-history-container {
  .presence-history-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .presence-history-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .training-row-table-header {
    display: flex;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
  }

  .presence-history-list {
    .no-presence-call-up-message {
      text-align: center;
      .no-presence-call-up-message-text {
        font-size: 15px;
        font-weight: 600;
        padding-top: 5px;
      }
    }

    .presence-row {
      margin-bottom: 15px;
      min-height: 55px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
    }
  }

  .presence-report-training-interactive {
    display: flex;
    align-items: center;
    gap: 25px;
    width: 100%;
    margin-bottom: 30px;

    .presence-training-date-pickers {
      height: 50px;
      width: 80%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;

      .presence-training-date-input {
        width: 100%;
        background-color: #fafafa;

        .MuiOutlinedInput-root {
          position: relative;
          border-radius: 4px;
        }
        .MuiOutlinedInput-input {
          padding: 18.5px 14px;
        }

        div {
          color: #b2b2b2;
          font-size: 14px;
          font-weight: 700;
          padding-left: 5px;
        }
      }
    }

    .training-teams-dropdown {
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      min-width: 200px !important;
      width: 10%;

      .divider.default.text {
        color: white;
      }

      i {
        top: 36% !important;
        color: white;
      }

      .divider.text {
        color: white;
      }
    }

    .show-presence-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      min-width: 100px;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .training-history-container.plan-training-list-container {
    .add-training-button {
      margin-right: 10px;
    }
  }

  .desktop-header {
    display: flex !important;
  }

  .header-row {
    display: none !important;
  }
}

@media only screen and (min-width: 1218px) {
  .show-presence-button {
    width: 10%;
  }
}

@media only screen and (max-width: 1218px) {
  .show-presence-button {
    width: 15%;
  }
}

@media only screen and (max-width: 991px) {
  .training-history-container.plan-training-list-container {
    .add-training-button {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
  .desktop-header {
    display: none !important;
  }

  .training-row-table-header.header-row {
    padding: 0 !important;
  }

  .header-row {
    display: block;
    width: 50%;
    height: 100%;
  }

  .mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    div {
      width: 100% !important;
      height: 37px !important;
      display: flex;
      justify-content: flex-start;
    }
  }

  .training-row {
    .content-row {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div:not(div:last-child, .optimal-weight div) {
        width: 100% !important;
        height: 45px !important;
        padding-bottom: 15px;
        display: flex;
        justify-content: flex-start;
      }

      .options-column {
        width: 100% !important;
        display: flex;
        justify-content: flex-end;
      }

      .player-options {
        width: 100% !important;
        justify-content: flex-start !important;

        .add-weight-button-container {
          width: 100% !important;
          height: 35px !important;
        }
      }
    }
  }

  .presence-history-container {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 800px) {
  .plan-training-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .training-history-header .plan-training-header {
    .training-history-title {
      margin-bottom: 10px;
    }
  }

  .planning-training-interactive {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .plan-training-title {
    margin-bottom: 50px;

    + div {
      .training-teams-dropdown {
        font-size: 11px !important;
        min-width: 150px !important;
      }

      button div {
        font-size: 11px !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }
}
