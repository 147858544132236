.accomodation-container {
  padding: 50px;

  .accomodation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .accomodation-title {
      font-size: 20px;
      font-weight: 700;
    }

    .add-rent-button {
      height: 50px;
      background-color: var(--primaryColor);
      color: white;
      width: 150px;

      .add-rent-white-icon {
        color: white;
      }
    }
  }

  .accomodation-list {
    max-height: 250px;
    overflow: auto;
  }
  .rent-row {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
    color: #666666;

    .rent-value {
      color: var(--primaryColor);
      display: flex;
      align-items: center;

      .rent-eur-icon {
        color: var(--primaryColor);
        padding-left: 5px;
      }
    }
  }
}

.add-rent-form-container {
  min-width: 450px;
  .add-rent-form-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;

    .add-rent-custom-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      i {
        margin: 0 !important;
      }

      input {
        display: flex;
        height: 50px;
        align-items: center;
        padding-left: 20px;
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }
    }

    .add-rent-custom-input {
      background-color: #fafafa;
      height: 100%;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding-left: 20px;
      border-radius: 3px;
    }
  }

  .add-rent-text-area {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding-left: 20px;
    padding-top: 10px;
    background-color: #fafafa !important;
    border: 0;
  }

  .add-rent-buttons-row {
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-top: 25px;
    margin-bottom: 40px;

    .rent-cancel-button {
      background-color: #666666;
      color: white;
      height: 50px;
      width: 47%;
    }

    .rent-add-button {
      background-color: #00d948;
      color: white;
      height: 50px;
      width: 47%;
    }
  }
}

.currency-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .currency-checkbox {
    height: 10px;
    width: 10px;
    border-radius: 3px;
    margin-right: 15px;
  }

  .currency-checkbox-selected {
    background-color: #666666;
  }

  .currency-name {
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2;
  }
}

.wages-detailed-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 60px;

  .wages-detailed-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
  }

  .players-list {
    margin-bottom: 30px;
  }
  .player-wage-row {
    width: 100%;
    display: flex;
    height: 80px;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #666666;

    .player-name {
      width: 28%;
      padding-left: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .player-avatar {
        margin-right: 15px;
      }
    }

    .player-month {
      width: 12%;
      text-align: center;

      .amount {
        display: flex;
        justify-content: center;

        i {
          color: #666666;
          margin-right: 0;
        }
      }
    }
  }

  .total-row {
    height: 73px;
    background-color: #666666;
    display: flex;
    align-items: center;
    font-weight: 700;

    .total-name {
      width: 52%;
      color: white;
      font-size: 14px;
      line-height: 28px;

      padding-left: 25px;
    }
  }

  .player-table-top {
    width: 12%;
    font-size: 13px;
    line-height: 31.75px;
    color: #b2b2b2;
    text-align: center;

    .total {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        font-size: 14px;
        line-height: 28px;
        color: var(--primaryColor);
      }

      i {
        color: var(--primaryColor);
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .player-wage-row {
    height: auto !important;
    padding: 20px;

    .mobile-header {
      padding: 0 !important;
      
      .player-name {
        padding-left: 0;
      }

      div {
        height: 50px !important;
        align-items: center;
      }

      .total {
        display: none;
      }

      .title {
        line-height: 1.75;
        text-align: left;
      }
    }

    .content-row {
      .player-month {
        display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
      }
      
      div:not(div.player-avatar) {
        width: 100%;
        height: 50px !important;
        padding: 0 !important;
      }

      .amount {
        justify-content: flex-start !important;
        align-items: center !important;

        i {
          height: 1.75em !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .container-fluid {
    padding: 0 !important;
  }

  .wages-detailed-container {
    padding: 20px !important;
  }
}