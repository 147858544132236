.send-email-container {
  padding: 50px;

  .send-email-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .send-email-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
  }

  .send-email-row {
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
  }

  .email-title-input {
    width: 100%;
    height: 100%;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 20px;
    background-color: #fafafa;
    color: #b2b2b2 !important;
    font-family: "Poppins", sans-serif !important;
  }
}
