.tfbIconButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  border: 0;
  transition: 0.2s;
  cursor: pointer;

  .tfbbtnicon {
  }

  .badgeContainer {
    position: relative;
    top: -15px;
    right: -15px;
    .badgeCircle {
      background-color: red;
      border-radius: 10px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .badgeNumber {
        color: white;
        font-size: 10px;
      }
    }
  }
}

.disabledBtn {
  opacity: 0.45 !important;
  box-shadow: none !important;
}

.sm {
  min-width: 35px;
  max-width: 35px;
  height: 35px;
}
.lg {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
}
.green {
  background: #00d948;
  color: #ffffff;

  img {
    filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);
  }
  &:hover {
    box-shadow: 0px 7.5px 15px rgba(0, 217, 72, 0.3);
  }
}

.darkGray {
  background: #666666;
  color: #ffffff;

  img {
    filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);
  }
  &:hover {
    box-shadow: 0px 7.5px 15px rgba(102, 102, 102, 0.25);
  }
}
.lightGray {
  background: #d4d4d4;
  color: #666666;

  img {
    filter: invert(40%) sepia(12%) saturate(5%) hue-rotate(342deg) brightness(95%) contrast(92%);
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(212, 212, 212, 0.55);
  }
}
.red {
  background: #d91200;
  color: #ffffff;

  img {
    filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(217, 18, 0, 0.25);
  }
}

.white {
  background: #ffffff;
  color: #000000;

  img {
    filter: invert(0%) sepia(84%) saturate(7391%) hue-rotate(217deg) brightness(85%) contrast(96%);
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(212, 212, 212, 0.55);
  }
}
