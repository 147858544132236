.wrong-effective-duration {
  border: 1px solid red !important;
}

.training-history-container.plan-training-list-container {
  .no-training-call-up-message {
    text-align: center;
    .no-training-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.plan-training-confirmed-presence {
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .plan-training-presence {
    display: flex;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    justify-content: center;
    align-items: center;
    color: #fafafa;
  }
}

.plan-training-actions-wrapper {
  gap: 5px;
  display: flex;
  justify-content: flex-end;
}

.training-history-list .training-row-table-header {
  display: flex;
}

.training-container .training-row-table-header {
  display: none !important;
}

.match-costs-edit-header {
  display: flex;
  align-items: center;
  gap: 10px;
  .arrow-back-nav {
    margin-bottom: 50px;
    cursor: pointer;
  }
}

.add-training-page-title {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 25px;
  color: black;
}

.edit-plan-training-container {
  // padding-top: 65px;
  // padding-bottom: 65px;
  // padding-left: 50px;
  // padding-right: 50px;

  .plan-training-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    height: 50px;
    // align-items: center;

    .plan-training-title {
      font-weight: 700;
      font-size: 20px;
      color: black;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .add-section-button {
      background-color: var(--primaryColor) !important;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      min-height: 50px;
      min-width: 230px !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      .add-section-button-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }

  .plan-training-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 25px;
    color: black;
    .add-training-row select {
      margin-bottom: -12px;
      margin-top: 20px;
    }
  }

  .plan-training-subtitle {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 700;
    color: black;
  }

  .plan-training-final-drop-area {
    border: 2px dashed #ededed;
    position: relative;
    margin-bottom: 65px;
    padding: 25px;
    display: flex;
    flex-direction: column;

    .sections-list {
      min-height: 175px;
      margin-bottom: 15px;
    }

    .plan-training-final-drop-row {
      display: flex;
      height: 50px;
      margin-bottom: 15px;
      justify-content: space-between;
      gap: 5px;

      .final-drop-row-name {
        width: 70%;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        color: #666666;
        font-size: 14px;
        font-weight: 700;

        .delete-icon-section-row {
          color: #666666;
        }
      }

      .wrong-effective-duration {
        border: 1px solid red !important;
      }

      .final-drop-row-input {
        width: 25%;
        background-color: #fafafa;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: #fafafa;
        color: #666;
      }
    }

    .final-drop-area-total-row {
      width: 100%;
      background-color: #666666;
      height: 55px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 700;

      .total-text {
        color: white !important;
      }

      .total-value {
        color: var(--primaryColor) !important;
      }
    }

    .final-drop-area-placeholder {
      font-size: 14px;
      color: #b2b2b2;
      font-weight: 700;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
    }
  }

  .plan-training-text-area {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
  }

  .plan-training-row {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .plan-training-hour-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .plan-training-date-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }
  }

  .plan-training-error-row {
    color: red;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }

  .add-training-save-button {
    background-color: #00d948 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    color: white !important;
    height: 50px;
    width: 100%;
  }

  .plan-training-error-row {
    text-align: center;
    color: red;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .add-section-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .add-section-input {
      width: calc(100% - 100px);
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .add-section-options {
      display: flex;
      align-items: center;
      .add-section-option-button {
        width: 50px;
        height: 50px;
        background-color: #666666;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .add-section-option-icon {
          opacity: 1 !important;
          color: white;
          margin: 0 !important;
        }
      }
    }
  }

  .existing-sections-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .existing-section-container {
      background-color: #fafafa;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      height: 50px;
      border-radius: 3px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      width: min-content;
      white-space: nowrap;
    }
  }
}

.add-plan-training-container {
  .plan-training-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    height: 50px;

    // align-items: center;

    .plan-training-title {
      font-weight: 700;
      font-size: 20px;
      color: black;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .add-section-button {
      background-color: var(--primaryColor) !important;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      min-height: 50px;
      min-width: 230px !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      .add-section-button-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }

  .plan-training-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 25px;
    color: black;
    .add-training-row select {
      margin-bottom: -12px;
      margin-top: 20px;
    }
  }

  .plan-training-subtitle {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 700;
    color: black;
  }

  .plan-training-final-drop-area {
    border: 2px dashed #ededed;
    position: relative;
    margin-bottom: 65px;
    padding: 25px;
    display: flex;
    flex-direction: column;

    .sections-list {
      min-height: 175px;
      margin-bottom: 15px;
    }

    .plan-training-final-drop-row {
      display: flex;
      height: 50px;
      margin-bottom: 15px;
      justify-content: space-between;
      gap: 5px;

      .final-drop-row-name {
        width: 70%;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        color: #666666;
        font-size: 14px;
        font-weight: 700;

        .delete-icon-section-row {
          color: #666666;
        }
      }

      .final-drop-row-input {
        width: 25%;
        background-color: #fafafa;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: #fafafa;
        color: #666;
      }
    }

    .final-drop-area-total-row {
      width: 100%;
      background-color: #666666;
      height: 55px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 700;

      .total-text {
        color: white !important;
      }

      .total-value {
        color: var(--primaryColor) !important;
      }
    }

    .final-drop-area-placeholder {
      font-size: 14px;
      color: #b2b2b2;
      font-weight: 700;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
    }
  }

  .plan-training-text-area {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
  }

  .plan-training-row {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .plan-training-hour-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .plan-training-date-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }
  }

  .plan-training-error-row {
    color: red;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }

  .add-training-save-button {
    background-color: #00d948 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    color: white !important;
    height: 50px;
    width: 100%;
  }

  .plan-training-error-row {
    text-align: center;
    color: red;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .add-section-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .add-section-input {
      width: calc(100% - 100px);
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .add-section-options {
      display: flex;
      align-items: center;
      .add-section-option-button {
        width: 50px;
        height: 50px;
        background-color: #666666;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .add-section-option-icon {
          opacity: 1 !important;
          color: white;
          margin: 0 !important;
        }
      }
    }
  }

  .existing-sections-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .existing-section-container {
      background-color: #fafafa;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      height: 50px;
      border-radius: 3px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      width: min-content;
      white-space: nowrap;
    }
  }
}

.add-training-row {
  select {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 18px;
    background-color: #fafafa;
    color: #b2b2b2 !important;
    margin-bottom: 15px;

    option {
      padding-left: 25px;
      background-color: #fafafa;
      color: #b2b2b2;
      font-size: 13px;
    }
  }
}

.training-history-container.plan-training-list-container {
  .plan-training-header {
    > div:last-child {
      display: flex;
    }
  }
  .add-training-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
  }

  .add-recurrent-training-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    margin-right: 10px;
  }
}

.add-venues-input {
  input {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 25px;
    background-color: #fafafa;
    color: #b2b2b2;
  }
}

.add-venues-dropdown {
  width: 100%;
  select {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #b2b2b2;

    option {
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
      font-size: 13px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .training-history-container.plan-training-list-container {
    .add-training-button {
      // background-color: var(--primaryColor) !important;
      // color: white !important;
      // font-family: "Poppins", sans-serif !important;
      // font-weight: 700;
      // height: 50px;
      margin-right: 10px;
    }
  }

  .desktop-header {
    display: flex !important;
  }

  .header-row {
    display: none !important;
  }

  .content-row {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {
  .training-history-container.plan-training-list-container {
    .add-training-button {
      // background-color: var(--primaryColor) !important;
      // color: white !important;
      // font-family: "Poppins", sans-serif !important;
      // font-weight: 700;
      // height: 50px;
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
  .desktop-header {
    display: none !important;
  }

  .training-row {
    // height: 230px !important;
    padding: 20px !important;
  }

  .training-row-table-header.header-row {
    padding: 0 !important;
  }

  .header-row {
    display: block;
    width: 50%;
    height: 100%;
  }

  .mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    div {
      width: 100% !important;
      height: 37px !important;
      display: flex;
      justify-content: flex-start;
    }
  }

  .training-row {
    .content-row {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div:not(div:last-child, .optimal-weight div) {
        width: 100% !important;
        height: 45px !important;
        padding-bottom: 15px;
        display: flex;
        justify-content: flex-start;
      }

      .options-column {
        width: 100% !important;
        display: flex;
        justify-content: flex-end;
      }

      .player-options {
        width: 100% !important;
        justify-content: flex-start !important;

        .add-weight-button-container {
          width: 100% !important;
          height: 35px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .plan-training-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .training-history-header .plan-training-header {
    .training-history-title {
      margin-bottom: 10px;
    }
  }

  .planning-training-interactive {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .plan-training-title {
    margin-bottom: 50px;

    + div {
      .training-teams-dropdown {
        font-size: 11px !important;
        min-width: 150px !important;
      }

      button div {
        font-size: 11px !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 490px) {
  .edit-plan-training-container {
    .add-training-row {
      .plan-training-row {
        flex-direction: column;
        height: max-content;
        gap: 15px;

        .plan-training-date-input {
          width: 100%;
          background-color: #fafafa;
        }

        .plan-training-hour-input {
          width: 100%;
          background-color: #fafafa;
        }
      }
    }
  }
}

@media only screen and (min-width: 1224px) {
  .add-plan-training-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 1224px) {
  .add-plan-training-desktop {
    display: none !important;
  }

  .add-plan-training-container .plan-training-subtitle {
    font-size: 16px;
    margin-bottom: 25px;
    padding-top: 15px;
    font-weight: 700;
    color: black;
  }

  .add-training-asd {
    .col-lg-6 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

@media only screen and (min-width: 1521px) {
  .mobile-add-training-table,
  .mobile-edit-training-table,
  .submit-add-training-mobile {
    display: none !important;
  }

  .training-start-hour-mobile,
  .training-end-hour-mobile {
    display: none !important;
  }

  .add-training-hours-error-mobile {
    display: none !important;
  }
}
.add-training-new-section-wrapper {
  margin-bottom: 15px;
  margin-top: 15px;
  .add-training-new-section {
    width: 100% !important;
    height: 50px !important;
    input {
      background-color: #fafafa !important;
      padding-left: 24px !important;
      border: 0 !important;
      font-family: "Poppins" !important;
      font-weight: 900 !important;
    }

    ::placeholder {
      color: #b2b2b2 !important;
    }
  }
}

@media only screen and (max-width: 1520px) {
  .add-training-hours-error-desktop {
    display: none !important;
  }

  .desktop-add-training-draggable,
  .desktop-edit-training-draggable,
  .submit-add-training-desktop {
    display: none !important;
  }

  .desktop-add-training-droppable,
  .desktop-edit-training-droppable {
    display: none !important;
  }

  .training-start-hour-desktop,
  .training-end-hour-desktop {
    display: none !important;
  }
}

// @media only screen and (max-width: 488) {
//   .add-training-row {
//     .plan-training-row {
//       display: flex !important;
//       flex-direction: column !important;
//     }
//   }
// }
@media only screen and (max-width: 490px) {
  .add-training-row {
    .plan-training-row {
      flex-direction: column;
      height: max-content;
      grid-gap: 15px;
      gap: 15px;

      .plan-training-date-input {
        width: 100%;
        background-color: #fafafa;
      }

      .plan-training-hour-input {
        width: 100%;
        background-color: #fafafa;
      }
    }
  }
}
