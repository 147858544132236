.dropdownGroup {
  .dropdownLabel {
    color: #b2b2b2;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
  }
}

.tfbDropdown {
  border: 0 !important;
  display: flex !important;
  align-items: center;
  padding-left: 20px !important;
  font-weight: 700;
  height: 50px;
}

.gray {
  background-color: #666666 !important;

  // margin-right: 20px;

  div {
    color: white !important;
    font-size: 14px !important;
    background-color: #666666 !important;
    font-weight: 600;
  }

  i {
    margin: 0 !important;
  }

  div.visible.menu.transition {
    div {
      background-color: #666666 !important;
      font-weight: 600;
    }
  }

  .tfbDropdownList {
    background-color: #666666 !important;
    .tfbDropdownItem {
      border-top: 0 !important;
      color: white !important;
      font-size: 14px;
    }
  }

  //   i {
  //     top: 30% !important;
  //     color: white;
  //   }

  //   .divider.text {
  //     color: white !important;
  //   }
}
.white {
  background-color: white !important;
  box-shadow: 0px 7.5px 15px rgba(102, 102, 102, 0.05);
  border-radius: 3px;
  // margin-right: 20px;

  div {
    color: #b2b2b2 !important;
    font-size: 14px !important;
    background-color: white !important;
    font-weight: 600;
  }

  i {
    margin: 0 !important;
  }

  div.visible.menu.transition {
    div {
      background-color: white !important;
      font-weight: 600;
    }
  }
  .tfbDropdownList {
    background-color: white !important;
    .tfbDropdownItem {
      border-top: 0 !important;
      color: #b2b2b2 !important;
      font-size: 14px;
    }
  }

  //   i {
  //     top: 30% !important;
  //     color: white;
  //   }

  //   .divider.text {
  //     color: white !important;
  //   }
}

.lightGray {
  background-color: #fafafa !important;
  box-shadow: 0px 7.5px 15px rgba(102, 102, 102, 0.05);
  border-radius: 3px;
  // margin-right: 20px;

  div {
    color: #b2b2b2 !important;
    font-size: 14px !important;
    background-color: #fafafa !important;
    font-weight: 600;
  }

  i {
    margin: 0 !important;
  }

  div.visible.menu.transition {
    div {
      background-color: #fafafa !important;
      font-weight: 600;
    }
  }
  .tfbDropdownList {
    background-color: #fafafa !important;
    .tfbDropdownItem {
      border-top: 0 !important;
      color: #b2b2b2 !important;
      font-size: 14px;
    }
  }

  //   i {
  //     top: 30% !important;
  //     color: white;
  //   }

  //   .divider.text {
  //     color: white !important;
  //   }
}
