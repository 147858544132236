.tfbCard {
  border: 0;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: max-content;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.42rem;

  .inner {
    padding: 45px;
  }
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .headerTitle {
    font-size: 20px;
    font-weight: 700;
  }
  .headerRight {
    display: flex;
    gap: 10px;
}
}

.cardBody {
    min-height: 0;
    min-width: 0;
}

@media only screen and (max-width: 768px) {
  .cardHeader {
    flex-direction: column;
    gap: 10px;

    .headerRight {
        flex-direction: column;
        width: 100%;
    }
  }

  .tfbCard {
    .inner {
      padding: 10px;
    }
  }

  .cardBody {
  }
}
