.add-user-container {
  .form-group {
    display: flex;
    flex-direction: column;
    .form-group-title {
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 34px;
      margin-top: 34px;
    }

    &:first-child {
      .form-group-title {
        margin-top: 0px;
      }
    }

    .add-input {
      height: 50px;
      font-size: 14px !important;
      font-weight: 700;
      color: #666666 !important;
      width: 100%;
    }

    .input-wrapper {
      .PhoneInputInput {
        height: 50px !important;
      }
    }
  }

  .team-access-wrapper {
    display: flex;
    flex-direction: column;
    .team-access-title {
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 34px;
      margin-top: 34px;
    }

    .teams-access {
      display: flex;
      background-color: #fafafa;
      width: 100%;
      height: 50px;
      align-items: center;
      gap: 20px;
      padding: 0px 10px;
      justify-content: space-between;

      .text-checkbox-container {
        display: flex;
        gap: 10px;
        .all-teams-text {
          font-weight: 900;
          font-size: 14px;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
          color: var(--primaryColor) !important;
        }
      }

      .extend-icon-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
      }
    }

    .teams-enum {
      width: 100%;
      padding: 0px 10px;
      display: flex;
      flex-flow: wrap;
      gap: 3px;
      height: max-content;
      background-color: #fafafa;

      .checkbox-text {
        display: flex;
        align-items: center;
        flex-flow: wrap;

        .MuiCheckbox-colorSecondary.Mui-checked {
          color: var(--primaryColor) !important;
        }
      }

      .team-name {
        font-weight: 700;
        color: #b2b2b2;
        cursor: pointer;
      }
    }
  }

  .module-access-container {
    display: flex;
    flex-direction: column;

    .module-access-title {
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 34px;
      margin-top: 34px;
    }

    .platform-modules-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .module-access-wrapper {
        display: flex;
        flex-direction: column;

        .modules-access {
          display: flex;
          background-color: #fafafa;
          width: 100%;
          height: 50px;
          align-items: center;
          gap: 20px;
          padding: 0px 10px;
          justify-content: space-between;

          .text-checkbox-container {
            display: flex;
            gap: 17px;
            .module-text {
              font-weight: 900;
              font-size: 14px;
            }

            .MuiCheckbox-colorSecondary.Mui-checked {
              color: var(--primaryColor) !important;
            }
          }

          .extend-icon-wrapper {
            position: relative;
            .extend-icon {
              position: absolute;
              right: 5px;
            }
          }
        }

        .submodule-enum {
          width: 100%;
          padding: 0px 10px;
          display: flex;
          flex-flow: wrap;
          gap: 15px;
          height: max-content;
          background-color: #fafafa;

          .checkbox-text {
            display: flex;
            align-items: center;
            flex-flow: wrap;

            .MuiCheckbox-colorSecondary.Mui-checked {
              color: var(--primaryColor) !important;
            }
          }

          .submodule-name {
            font-weight: 700;
            color: #b2b2b2;
          }
        }
      }
    }
  }

  .add-user-footer-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 34px;
    gap: 10px;
  }
}

.delete-user-popup-container {
  .delete-user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .delete-user-title {
      font-size: 20px;
      font-weight: 700;
    }

    .close {
      cursor: pointer;
    }
  }

  .delete-user-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-user-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    gap: 20px;
    .cancel-button {
      color: #fafafa;
      height: 50px;
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      background-color: #666666;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }
    .delete-comp-button {
      background-color: #db2828;
      height: 50px;
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      color: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }
  }
}

.user-management-edit-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  margin-bottom: 10px;

  .user-management-edit-title {
    font-size: 20px;
    font-weight: 700;
  }
}
